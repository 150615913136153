import React from 'react';
import container from './css/style.css'

const rawHtml = `<!DOCTYPE html>
<html lang="en">

<head>
\t<meta charset="utf-8">
\t<meta http-equiv="X-UA-Compatible" content="IE=edge">
\t<meta name="viewport" content="width=device-width, initial-scale=1">
\t<!-- The above 3 meta tags *must* come first in the head; any other head content must come *after* these tags -->

\t<title>404 HTML Template by Colorlib</title>

\t<link href="https://fonts.googleapis.com/css?family=Nunito:400,700" rel="stylesheet">

\t<link type="text/css" rel="stylesheet" href="css/style.css" />

</head>

<body>

\t<div id="notfound">
\t\t<div class="notfound">
\t\t\t<div class="notfound-404"></div>
\t\t\t<h1>401</h1>
\t\t\t<h2>Oops! Unauthorized</h2>
\t\t\t<p>We signed you out, because of inactivity. Click the button to return back to the login screen and sign in again</p>
\t\t\t<a href="/login">Back to login</a>
\t\t</div>
\t</div>

</body>

</html>`
const UnauthorizedError = () => {
    return (
        <>
            <div style={container}>
                <div dangerouslySetInnerHTML={{ __html: rawHtml }}></div>
            </div>
        </>
    )

}

export default UnauthorizedError;
