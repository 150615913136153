import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_5_2 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">
                      Diagnostic Injection to your Joint(s)/Bursa
                    </h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">About the Procedure.</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      <span className="bold">A local anesthetic</span> (numbing
                      medication) will be injected into the joint. The
                      anesthetic typically numbs the area for 1-4 hours
                      depending on the anesthetic used, and may provide some
                      immediate relief. The purpose of the injection is to
                      diagnose the cause of your pain.
                    </p>
                    <p>
                      This procedure uses a{" "}
                      <span className="bold">contrast agent</span>, which is a
                      safe substance that makes a part of your body show up more
                      clearly on an X-ray to ensure proper placement of the
                      medicine.{" "}
                    </p>
                    <p>
                      Should you have any questions regarding your procedure,
                      contact our office at
                      <span className="bold">
                        &nbsp;
                        <a className="phoneLink" href="tel:314-273-1832">
                          314-273-1832
                        </a>
                      </span>
                      .
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  <div className="col-12 m-auto" style={{ width: "80%" }}>
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003B">
                      <h2 className="subHeadCaptalizedItalic">
                        <span className="playButton">&#9658;</span>{" "}
                        {/* Play button as an SVG */}
                        Important Notice:
                      </h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}

                    {/* L14A Paragraph With Bold Spans */}
                    <div className="lego l00paragraph1 legoMargin1">
                      <p>
                        For your safety, patients with a{" "}
                        <span className="boldItalic">
                          BMI of 50 or above cannot be scheduled{" "}
                        </span>
                        for fluoroscopy (fluoro) procedures at our South County
                        CAM and Chesterfield OC locations. Please contact our
                        office if you need assistance with rescheduling or have
                        any questions.
                      </p>
                    </div>
                    {/* End L14A Paragraph With Bold Spans */}

                    {/* L66 Divider Rule */}
                    <div className="lego l066 legoMargin1">
                      <hr className="orangeHorizonalRule" />
                    </div>
                    {/* End L66 Divider Rule */}
                  </div>

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">PRIOR TO THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L88 List with Background Color */}
                  <div className="lego l088 legoMargin5">
                    <div className="contentContainer">
                      {/* L53 Bullet List */}
                      <div className="lego l053 smallFont">
                        <ul className="w-100">
                          <li className="w-100 legoMargin2">
                            <span className="bold italic">
                              Allergies to x-ray contrast dye or local
                              anesthetics:
                            </span>{" "}
                            Alert our office if you have any history of
                            allergies to local anesthetics (such as Novocain or
                            lidocaine) or x-ray contrast dye.
                          </li>
                          <li className="w-100">
                            You{" "}
                            <span className="bold italic">
                              DO NOT need to stop medications
                            </span>{" "}
                            (blood pressure meds, diabetes meds, etc.) prior to
                            the procedure.
                          </li>
                        </ul>
                      </div>
                      {/* End L53 Bullet List */}
                    </div>
                  </div>
                  {/* End L88 List with Background Color */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">DAY OF THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin5">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Please arrive 30 minutes prior to your injection time
                          for registration.
                        </span>
                        &nbsp;If you arrive more than 15 minutes past your
                        scheduled injection time, your procedure may need to be
                        rescheduled.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">AFTER THE PROCEDURE</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83D Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          You will be given written discharge instructions
                        </span>{" "}
                        after your procedure along with a pain diary to track
                        your response to the injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83D Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Resume your medicine
                        </span>
                        &nbsp;including any blood thinner you held prior to the
                        injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_fork_meat.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>Resume your normal diet.</p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_vac.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Avoid having a vaccination two weeks
                        </span>{" "}
                        after the procedure as your immune response maybe
                        quieted by the steroids.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_exer.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        You should{" "}
                        <span className="bold italic">
                          resume activity as tolerated, especially those that
                          hurt prior to the injection
                        </span>
                        &nbsp;to see if the injection worked.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img className="icon"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_bath.svg"/>
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Stay out of a pool, bath or hot tub
                        </span>{" "}
                        for two days after the procedure.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_icepack.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        For soreness, you may place an ice pack once an hour at
                        the injection site for 15-20 minutes.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_glass_water.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        Drink plenty of fluids to decrease a chance of a
                        headache associated with steroids.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L84 Block */}
                  <div className="lego l084 legoMargin5">
                    <h3 className="contentTitle">
                      Billing for Injection Procedures
                    </h3>
                    <p>
                      Our Washington University orthopedic specialists treat
                      patients at BJC HealthCare facilities, which means you may
                      receive two separate bills. One bill is for the physician
                      and the other is for the facility charges.{" "}
                    </p>

                    {/* L82? Content 2 sides 50% width */}
                    <div className="lego l082 p-0">
                      <div className="contentLeftSide">
                        {/* L11 Image */}
                        <div className="lego l011 legoMargin3">
                          <img
                            className="imageW100 noBorderRadius"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"
                          />
                        </div>
                        {/* End L11 Image */}
                      </div>
                      <div className="contentRightSide">
                        <div className="lego l00paragraph legoMargin1">
                          <p>
                            <span className="italic">
                              If you have questions regarding a price estimation
                              for the services or a recently received bill,
                              please contact:
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Price Estimation:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3147478845">
                                314-747-8845
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18447478845">
                                844-747-8845
                              </a>
                            </span>
                          </p>
                          <p>
                            Washington University Patient Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3142730500">
                                314-273-0500
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18008629980">
                                800-862-9980
                              </a>
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Patient Billing Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3143628400">
                                314-362-8400
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18553628400">
                                855-362-8400
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End L82? Content 2 sides 50% width */}
                  </div>
                  {/* End L84 Block */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_5_2;
