import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_3_4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Medial Branch Block (MBB)</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">About the Procedure.</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin4">
                    <p>
                      Each vertebra (small bone) in your spine has flat areas
                      called facets. They touch where the vertebrae fit
                      together, forming a facet joint. Each facet joint has two
                      medial branch nerves. In a MBB, local anesthetic (numbing)
                      medicine is injected to the medial branch nerves that
                      connect to the facet joints of the spine.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L11B Image Max Width 400px */}
                  <div className="lego l011 legoMargin4 d-flex justify-content-center">
                    <img
                      className="imageW100 maxWidth400"
                      alt=""
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/389f9d194c0fe0b172c13d06fe029a81.png"
                    />
                  </div>
                  {/* End L11B Image Max Width 400px */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">WILL IT REDUCE MY PAIN?</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin1">
                    <p>
                      This injection is not a treatment. It is used to find the
                      source of your pain. It can tell your provider if a
                      certain nerve is involved in the pain you are feeling. The
                      local anesthetic (numbing medicine) used in the injection
                      may give you pain relief right away. The pain relief can
                      last several hours, but it is only short-term. Depending
                      on the results of this injection, your provider may
                      suggest another type of procedure or treatment.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">BEFORE THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin3">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Please arrive 30 to 60 minutes prior
                        </span>
                        &nbsp;to your injection time for registration.
                        <br /> Continue your regular medication schedule,
                        especially blood pressure and diabetes medications.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  <div className="col-12 m-auto" style={{ width: "80%" }}>
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003B">
                      <h2 className="subHeadCaptalizedItalic">
                        <span className="playButton">&#9658;</span>{" "}
                        {/* Play button as an SVG */}
                        Important Notice:
                      </h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}

                    {/* L14A Paragraph With Bold Spans */}
                    <div className="lego l00paragraph1 legoMargin1">
                      <p>
                        For your safety, patients with a{" "}
                        <span className="boldItalic">
                          BMI of 50 or above cannot be scheduled{" "}
                        </span>
                        for fluoroscopy (fluoro) procedures at our South County
                        CAM and Chesterfield OC locations. Please contact our
                        office if you need assistance with rescheduling or have
                        any questions.
                      </p>
                    </div>
                    {/* End L14A Paragraph With Bold Spans */}

                    {/* L66 Divider Rule */}
                    <div className="lego l066 legoMargin1">
                      <hr className="orangeHorizonalRule" />
                    </div>
                    {/* End L66 Divider Rule */}
                  </div>

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">AFTER THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin5">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          You will be given written discharge instructions
                        </span>{" "}
                        after your procedure along with a pain diary to track
                        your response to the injection. Two days after the
                        procedure, you will call your physician office to give
                        an update regarding your response to the injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">WHAT HAPPENS NEXT?</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 ">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_calendar.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          You will be scheduled to have two MBB injections.
                        </span>
                        &nbsp;Your response to the injections will determine if
                        you will move forward with having a{" "}
                        <span className="bold">Radiofrequency Ablation</span>.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L84 Block */}
                  <div className="lego l084 legoMargin5">
                    <h3 className="contentTitle">
                      Billing for Injection Procedures
                    </h3>
                    <p>
                      Our Washington University orthopedic specialists treat
                      patients at BJC HealthCare facilities, which means you may
                      receive two separate bills. One bill is for the physician
                      and the other is for the facility charges.{" "}
                    </p>

                    {/* L82? Content 2 sides 50% width */}
                    <div className="lego l082 p-0">
                      <div className="contentLeftSide">
                        {/* L11 Image */}
                        <div className="lego l011 legoMargin3">
                          <img
                            className="imageW100 noBorderRadius"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"
                          />
                        </div>
                        {/* End L11 Image */}
                      </div>
                      <div className="contentRightSide">
                        <div className="lego l00paragraph legoMargin1">
                          <p>
                            <span className="italic">
                              If you have questions regarding a price estimation
                              for the services or a recently received bill,
                              please contact:
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Price Estimation:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3147478845">
                                314-747-8845
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18447478845">
                                844-747-8845
                              </a>
                            </span>
                          </p>
                          <p>
                            Washington University Patient Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3142730500">
                                314-273-0500
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18008629980">
                                800-862-9980
                              </a>
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Patient Billing Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3143628400">
                                314-362-8400
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18553628400">
                                855-362-8400
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End L82? Content 2 sides 50% width */}
                  </div>
                  {/* End L84 Block */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_3_4;
