import React, {useContext} from "react";
import Container from "react-bootstrap/Container";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionButton} from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

const expand = 'expand';
const collapse = 'collapse';

function ContextAwareToggle({children, eventKey, callback}) {
    const {activeEventKey} = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            className="accordionExpand"
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

const LivingWellCenter_9 = () => {

    return (
        <div>
            <div className="w-100 legosNewBuild livingWellCenterHeader">
                <section className="mb-0">
                    {/* L6 Background image header bar */}
                    <div className="lego l006 legoMargin5">
                        <div
                            className="backgroundImage"
                            style={{
                                backgroundImage:
                                    "url(" +
                                    "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/55d6001ebd538e95275545fa9b4dc8e0.jpeg" +
                                    ")",
                            }}
                        >
                            <div
                                className="transparentBarTypeContainer"
                                style={{
                                    backgroundColor: "#9546B5;",
                                }}
                            >
                                &nbsp;&nbsp;
                            </div>
                            <h2 className="l6SubheadWithBackground">
                                Tai Chi & Yoga Programs
                            </h2>
                        </div>
                    </div>
                    {/* End L6 Background image header bar */}
                </section>
                <Container>
                    <div className="w-100">
                        <div className="col-12 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                                <section>


                                    <Accordion defaultActiveKey={['0', '1', '2', '3', '4']} alwaysOpen
                                               className="faqAccordion">
                                        <Card className="legoMargin5">
                                            <Card.Header><h2>Where are you located?</h2>
                                                <ContextAwareToggle eventKey="0">expand </ContextAwareToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>

                                                    <div className="lego l00paragraph">
                                                        <p>For complete information on all Washington University
                                                            Department
                                                            of
                                                            Orthopedics locations visit <a
                                                                className="consistentLinkColor"
                                                                href="https://www.ortho.wustl.edu/content/Patient-Care/2321/Appointment-Information/Office-Locations.aspx"
                                                                target="_blank">here</a>.</p>
                                                    </div>
                                                    <ContextAwareToggle eventKey="0">collapse</ContextAwareToggle>
                                                </Card.Body>
                                            </Accordion.Collapse>

                                        </Card>
                                        <Card className="legoMargin5">
                                            <Card.Header><h2>What insurance plans do you accept?</h2>
                                                <ContextAwareToggle eventKey="1">expand</ContextAwareToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <div className="lego l00paragraph">
                                                        <p>Washington University Orthopedics participates with most
                                                            medical
                                                            insurance plans. However, private or company insurance plans
                                                            and
                                                            plan
                                                            networks change frequently. Please verify coverage before
                                                            your
                                                            appointment.</p>
                                                        <p>For an updated listing of accepted health insurance plans and
                                                            networks,
                                                            please visit the <a className="consistentLinkColor"
                                                                                href="https://physicians.wustl.edu/for-patients/health-insurance/"
                                                                                target="_blank">Washington University
                                                                Physicians website</a>.
                                                        </p>
                                                    </div>
                                                    <ContextAwareToggle eventKey="1">collapse</ContextAwareToggle>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="legoMargin5">
                                            <Card.Header><h2>How can I request medical information like my medical
                                                records?</h2>
                                                <ContextAwareToggle eventKey="2">expand</ContextAwareToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <div className="lego l00paragraph">
                                                        <p>You can request your medical records <a
                                                            className="consistentLinkColor"
                                                            href="https://www.ortho.wustl.edu/content/Patient-Care/3504/APPOINTMENT-INFORMATION/Request-Medical-Records.aspx"
                                                            target="_blank">here</a></p>
                                                        <p>If you need hospital records from Barnes-Jewish Hospital or
                                                            St.
                                                            Louis Children’s Hospital, you will need to request them
                                                            directly from the hospital.</p>
                                                        <p><span className="bold">Barnes-Jewish Hospital<br/> Health Information Management Department</span><br/>
                                                            <a href="tel:3144545934"
                                                               className="phoneLink">314-454-5934</a> (Option 1)</p>
                                                        <p><span className="bold">St. Louis Children’s Hospital Medical Records</span><br/>
                                                            <a href="tel:3144546060"
                                                               className="phoneLink">314-454-6060</a>
                                                        </p>
                                                        <p>If you have additional questions on this, you can
                                                            contact:<br/>
                                                            <span className="bold">Phone:</span> <a
                                                                href="tel:3142730453"
                                                                className="phoneLink">314-273-0453</a><br/>
                                                            <span className="bold">Email:</span> <a
                                                                className="consistentLinkColor"
                                                                href="mailto:hirs@wustl.edu">hirs@wustl.edu</a></p>
                                                    </div>
                                                    <ContextAwareToggle eventKey="2">collapse</ContextAwareToggle>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="legoMargin5">
                                            <Card.Header><h2>What are your billing policies?</h2>
                                                <ContextAwareToggle eventKey="3">expand</ContextAwareToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <div className="lego l00paragraph">
                                                        <p>Our mission is to meet the needs of those we serve. We have
                                                            established a policy of communicating financial expectations
                                                            to
                                                            our patients. You can review our billing and payment
                                                            policies on
                                                            payment options for self-pay patients, patients with
                                                            insurance,
                                                            and additional services such as radiology and durable
                                                            medical
                                                            equipment <a className="consistentLinkColor"
                                                                         href="https://www.ortho.wustl.edu/content/Patient-Care/8167/Appointment-Information/Billing-and-Payment-Information.aspx"
                                                                         target="_blank">here</a></p>
                                                    </div>
                                                    <ContextAwareToggle eventKey="3">collapse</ContextAwareToggle>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card className="legoMargin5">
                                            <Card.Header>
                                                <h2>What are your COVID-19 policies?</h2>
                                                <ContextAwareToggle eventKey="4">expand</ContextAwareToggle>
                                            </Card.Header>

                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <div className="lego l00paragraph">
                                                        <p>You can access our current COVID-19 policies <a
                                                            className="consistentLinkColor"
                                                            href="https://www.ortho.wustl.edu/content/About-Us/8294/Keeping-You-Safe.aspx"
                                                            target="_blank">here</a></p>
                                                    </div>
                                                    <ContextAwareToggle eventKey="4">collapse</ContextAwareToggle>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>

                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p>
                                                <a
                                                    className="specialUseBackToTop"
                                                    onClick={() =>
                                                        window.scrollTo({top: 0, behavior: "smooth"})
                                                    }
                                                >
                                                    BACK TO TOP{" "}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default LivingWellCenter_9;
