// LOGOUT
import jwt_decode from "jwt-decode";
import {setLoginScreenStatus} from "../../helpers/index.js";

export const logout = () => {
    localStorage.clear();
}

// LOGIN STATUS
export const isLogin = (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token || jwt_decode(token).exp < Date.now() / 1000) {
        dispatch({
            type: 'RESET'
        });
        logout();
        setLoginScreenStatus(true);
        return false;
    }

    return localStorage.getItem("expiresIn") && token && jwt_decode(token).exp > Date.now() / 1000;
}
