import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_1_5 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin4">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/4946e550fcb6294d4fe781077bba8856.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#8DCA64",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Contact Us</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">Take the First Step</h2>
                  </div>
                  {/* End L2 */}

                  {/* L83A Icon Color Bar New */}
                  <div
                    className="lego l083AA legoMargin3"
                    style={{ borderRadius: 0 }}
                  >
                    <img
                      alt=""
                      className="imageW100 noBorderRadius maxWidth600"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/af97876010a2f3cd1e39df819e44ab54.jpeg"
                    />
                    <div className="contentContainer legoMargin3">
                      <p>
                        Find out how the Living Well Center can help you. <br />
                        Schedule your consultation by calling today
                      </p>
                      <h2 className="boldLeadInNewUI legoMargin3">
                        (314) 514-3565
                      </h2>
                      <p>
                        <span className="boldHeadLine"> Fax: </span>
                        (314) 996-8132 <br />
                        <span className="boldHeadLine">Emergency: </span> In case of a true medical emergency, dial 911<br/>
                        <span className="boldHeadLine">After Hours: </span>  For urgent after hours issues, <br/>
                        call (314) 388-5550
                      </p>
                    </div>
                  </div>
                  {/* End L83A Icon Color Bar New */}

                  {/* L83A Icon Color Bar New */}
                  <div
                    className="lego l083AA legoMargin5"
                    style={{ borderRadius: 0 }}
                  >
                    <img
                      alt=""
                      className="imageW100 noBorderRadius maxWidth600"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7606a1e9ab123c469fb561dd57c536d2.png"
                    />
                    <div className="contentContainer">
                      <div className="legoMargin3">
                        <h2 className="boldHeadLine">
                          Washington University Living Well Center®
                        </h2>
                        <p>
                          Barnes-Jewish West County Hospital
                          <br />
                          Medical Office Building 4<br />
                          1044 North Mason Road, Suite 210
                          <br />
                          Creve Coeur, MO 63141
                          <br />
                        </p>
                      </div>
                      <p>
                        <span className="italic">
                          {" "}
                          Free parking onsite available.  
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* End L83A Icon Color Bar New */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin4">
                    <p style={{ textAlign: "center" }}>
                      <a
                        className="blueLinkfont24"
                        href="https://www.ortho.wustl.edu/content/Patient-Care/7140/Services/Living-Well-Center.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        livingwellcenter.wustl.edu
                      </a>{" "}
                      <img
                        className="smallImgIcon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2d8ed8935ceec4b166dfc12294a57b7d.png"
                        alt=""
                      />
                      <a
                        className="blueLinkfont18"
                        href="https://www.facebook.com/WashULivingWellCenter/videos/theres-a-lot-of-chopping-dicing-and-slicing-involved-with-plantbased-cooking-her/386987456329143/?_rdr"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        #LivingWellSTL
                      </a>{" "}
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_1_5;
