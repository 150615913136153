import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@material-ui/core/Button';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {invitePatient} from "../../actions/invitePatient.js";
import {
    validateDiagnoses,
    validateEmailAddress,
    validateFirstName,
    validateLastName,
    validateOrganization
} from "./validation.js";
import Select from "@material-ui/core/Select";
import {InputWithErrorMessage} from "../forms/inputWithErrorMessage.js";
import styles from "./patientinvite.module.scss";
import {userProfile} from "../../actions/userProfile.js";
import {getOrbitList, getUser} from "../../helpers/index.js";
import {diagList} from "../../actions/diagList.js";
import {orgList} from "../../actions/orgList.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, SYS_ADMIN} from "../../constants/roles.js";
import {diagnosesList} from "../../actions/diagnosisList.js";
import {diagnosisUserList} from "../../actions/diagnosisUserList.js";
import {validatePassword, validatePhoneNumber, validateUserName} from "../auth/register/validation.js";
import InputMask from "react-input-mask";
import {generateOrbitIntegrationCode} from "../../actions/orbitIntegrationCode.js";
import {toast, ToastContainer} from "react-toastify";

const PatientInvite = () => {

    const user = JSON.parse(getUser());
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [orbitIntegrationCode, setOrbitIntegrationCode] = useState({});
    const [emailAddress, setEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [diagnoses, setDiagnoses] = useState([]);
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [organizationId, setOrganizationId] = useState("");
    const [orbitIdList, setOrbitIdList] = useState([]);
    const [organizationList, setOrganizationList] = useState([]);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [diagnosesError, setDiagnosesError] = useState("");
    const [organizationIdError, setOrganizationIdError] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [sendType, setSendType] = useState("Both");
    const [isDirty, setIsDirty] = useState(false);
    const {errorMessage} = useSelector(state => state.register);
    const [displayOrbitIntegrationCode, setDisplayOrbitIntegrationCode] = useState(false);
    const {
        defaultOrbit,
        diagnosticId
    } = useSelector(state => {
        return state.home
    });

    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (userMessage) {
            toast.success(userMessage);
        }

    }, [userMessage])

    const submitForm = async (e) => {
        let tempPhoneNumber = '';
        let tempEmailAddress = '';

        e.preventDefault();

        if (!await validateAllFields())
            return;

        if (emailAddress && emailAddress.length === 0)
            tempEmailAddress = null;
        else
            tempEmailAddress = emailAddress;

        if (phoneNumber && phoneNumber.length === 0)
            tempPhoneNumber = null;
        else
            tempPhoneNumber = phoneNumber;

        const ip = await invitePatient({
            diagnoses,
            organizationId,
            firstName,
            lastName,
            orbitIntegrationCode: orbitIntegrationCode?.orbitIntegrationCode,
            emailAddress: tempEmailAddress,
            orbitIdList,
            sendType,
            phoneNumber: tempPhoneNumber
        }, () => {
            debugger;
            setUserMessage('Invitation Sent!');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        })
        await dispatch(ip);
    }

    useEffect(async () => {
        if (user.organizationId === '26f0cfc0-1def-11ef-8ace-f7d33dacea23' &&
            user?.roles.indexOf(SYS_ADMIN) === -1) {
            const oic = await generateOrbitIntegrationCode((integrationCode) => {
                setOrbitIntegrationCode(integrationCode);
                setDisplayOrbitIntegrationCode(true);
            })
            await dispatch(oic);
        }

        if (!orbitIdList || orbitIdList.length === 0 || (orbitIdList.length > 0 && (orbitIdList[0]?.length === 0 || orbitIdList[0] === null))) {
            //setOrbitIdList(user?.defaultOrbit ? user?.defaultOrbit : defaultOrbit);
            setOrbitIdList(!Array.isArray(defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit) ?
                [defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit] :
                defaultOrbit && defaultOrbit.length > 0 ? defaultOrbit : user?.defaultOrbit);
        }

        if (user?.roles.indexOf(SYS_ADMIN) !== -1) {
            const dl = await diagnosisUserList({organizationId}, (data) => {
                setDiagnosisList(data);
            });
            await dispatch(dl);
        }
        const ol = await orgList({}, (data) => {
            setOrganizationList(data);
        });
        if ((user?.roles.indexOf(DEPARTMENT_ADMIN) !== -1 ||
            user?.roles.indexOf(ORBIT_ADMIN) !== -1)) {
            setDiagnoses(Array.isArray(diagnosticId) ? diagnosticId : diagnosticId ? [diagnosticId] : null);
        }

        await dispatch(ol);
    }, []);

    useEffect(async () => {
        if (organizationId === '26f0cfc0-1def-11ef-8ace-f7d33dacea23' &&
            (orbitIntegrationCode?.length === 0) &&
            user?.roles.indexOf(SYS_ADMIN) !== -1) {
            const oic = await generateOrbitIntegrationCode((integrationCode) => {
                setOrbitIntegrationCode(integrationCode);
                setDisplayOrbitIntegrationCode(true);
            })
            await dispatch(oic);
        }

        if (user?.roles.indexOf(SYS_ADMIN) !== -1) {
            const dl = await diagnosisUserList({organizationId}, (data) => {
                setDiagnosisList(data);
            });
            await dispatch(dl);
        }
    }, [organizationId])

    const changeFirstName = (e) => setFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);
    const changeEmailAddress = (e) => setEmailAddress(e.target.value);
    const changePhoneNumber = (e) => setPhoneNumber(e.target.value);
    const changeDiagnosis = (e) => setDiagnoses([parseInt(e.target.value)]);
    const changeOrganization = (e) => setOrganizationId(e.target.value);
    const changeSendType = (e) => setSendType(e.target.value);

    const validateOrganizationField = async (e) => {
        if (user.roles && user.roles.indexOf(SYS_ADMIN) !== -1) {
            await setIsDirty(true);
            await setOrganizationIdError(validateOrganization(organizationId));
        }
    }

    const validateDiagnosisField = async (e) => {
        if (user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1)) {
            await setIsDirty(true);
            await setDiagnosesError(validateDiagnoses(diagnoses));
        }
    }

    const validateFirstNameField = async (e) => {
        await setIsDirty(true);
        await setFirstNameError(validateFirstName(firstName));
    }

    const validateLastNameField = async (e) => {
        await setIsDirty(true);
        await setLastNameError(validateLastName(lastName));
    }

    const validateEmailAddressField = async (e) => {
        await setIsDirty(true);
        await setEmailAddressError(validateEmailAddress(emailAddress));
    }

    const validatePhoneNumberField = async (e) => {
        await setIsDirty(true);
        await setPhoneNumberError(validatePhoneNumber(phoneNumber));
    }

    const validateAllFields = async () => {
        await setIsDirty(true);
        const invalidFirstName = validateFirstName(firstName);
        const invalidLastName = validateLastName(lastName);
        let invalidOrganization = '';
        let invalidDiagnosis = '';
        let invalidEmailAddress = '';
        let invalidPhoneNumber = '';

        if (user.roles && user.roles.indexOf(SYS_ADMIN) !== -1) {
            invalidOrganization = validateOrganization(organizationId);
            setOrganizationIdError(invalidOrganization);
        }

        if (diagnosisList && diagnosisList.length > 0 &&
            user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1 ||
                user.roles.indexOf(ORGANIZATION_ADMIN) !== -1)) {
            invalidDiagnosis = validateDiagnoses(diagnoses);
            setDiagnosesError(invalidDiagnosis);
        }

        if (sendType === 'Email') {
            invalidEmailAddress = validateEmailAddress(emailAddress);
            setEmailAddressError(invalidEmailAddress);
        } else if (sendType === 'Text') {
            invalidPhoneNumber = validatePhoneNumber(phoneNumber);
            setPhoneNumberError(invalidPhoneNumber);
        } else if (sendType === 'Both') {
            invalidEmailAddress = validateEmailAddress(emailAddress);
            invalidPhoneNumber = validatePhoneNumber(phoneNumber);
            setEmailAddressError(invalidEmailAddress);
            setPhoneNumberError(invalidPhoneNumber);
        }

        setFirstNameError(invalidFirstName);
        setLastNameError(invalidLastName);

        if (invalidFirstName.length > 0 ||
            invalidLastName.length > 0 ||
            invalidEmailAddress.length > 0 ||
            invalidPhoneNumber.length > 0 ||
            invalidOrganization.length > 0 ||
            invalidDiagnosis.length > 0)
            return false;
        else
            return true;
    }

    const sendTextTypeBool = sendType === 'Text' || sendType === 'Both';
    const sendEmailTypeBool = sendType === 'Email' || sendType === 'Both';

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        <h1 className="text-center title">Invite a new user</h1>
                        <h2 className="text-center title">The new user will receive an invitation link to activate their
                            guide.</h2>
                        <div className="material-box"
                             style={{display: (displayOrbitIntegrationCode ? 'inline' : 'none')}}>
                            <h2 style={{color: '#000000', margin: '20px'}}>The Orbit Integration Code for this invitation is:</h2>

                            <h2 style={{
                                color: '#000000',
                                fontSize: '30px',
                                margin: '20px'
                            }}>{orbitIntegrationCode?.orbitIntegrationCode}</h2>

                            <h2 style={{color: '#000000', margin: '20px'}}>Please copy the value and paste it into the Orbit Integration Code field for this patient in GTS.</h2>
                        </div>
                        <Box className="material-box" component="form" onSubmit={submitForm} autoComplete="off">
                            <div className="card-body">
                            <div className="formFlexContainer">
                                    <FormControl className="w-100">
                                        <FormLabel className="text-dark" id="demo-radio-buttons-group-label">Choose
                                            Invitation Type</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Both"
                                            value={sendType}
                                            onChange={changeSendType}
                                            id="sendType"
                                            name="radio-buttons-group">
                                            <FormControlLabel value="Text" className="text-dark" control={<Radio/>}
                                                              label="Text"/>
                                            <FormControlLabel value="Email" className="text-dark" control={<Radio/>}
                                                              label="Email"/>
                                            <FormControlLabel value="Both" className="text-dark" control={<Radio/>}
                                                              label="Both"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                {sendTextTypeBool &&
                                    <div className="formFlexContainer">
                                        <h3 className="text-center title">Invite by SMS text</h3>
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={phoneNumberError}>
                                                {/*<TextField className="w-100" name="phone" id="phone" type="phone"*/}
                                                {/*           label="Phone number of the user to invite by SMS" variant="standard"*/}
                                                {/*           value={phoneNumber} onChange={changePhoneNumber}*/}
                                                {/*           onBlur={validatePhoneNumberField}*/}
                                                {/*           helperText="Please enter the phone number of the user you would like to invite"*/}
                                                {/*           error={phoneNumberError}></TextField>*/}
                                                <InputMask
                                                    className="w-100" name="phone" id="phone"
                                                    variant="standard"
                                                    mask="(999) 999-9999"
                                                    value={phoneNumber} onChange={changePhoneNumber}
                                                    onBlur={validatePhoneNumberField}
                                                    maskChar=" "
                                                >
                                                    {() => <TextField className="w-100"
                                                                      type="phone" variant="outlined" label="Phone"
                                                                      helperText=""/>}
                                                </InputMask>
                                            </InputWithErrorMessage>
                                        </div>
                                    </div>
                                }
                                <div className="formFlexContainer">
                                    {sendEmailTypeBool &&
                                        <div className="formInput">
                                            <h3 className="text-center title">Invite by Email</h3>
                                            <div className="formInput">
                                                <InputWithErrorMessage errorMessage={emailAddressError}>
                                                    <TextField className="w-100" name="email" id="email" key="email"
                                                               type="email"
                                                               label="Email" variant="outlined"
                                                               helperText=""
                                                               value={emailAddress} onChange={changeEmailAddress}
                                                               error={!!emailAddressError}
                                                               onBlur={validateEmailAddressField}
                                                                required/>
                                                    <span style={{color: "#ff0000"}}>{errorMessage}</span>
                                                </InputWithErrorMessage>
                                            </div>
                                        </div>
                                    }
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={firstNameError}>
                                            <TextField className="w-100" name="firstName" id="firstName" key="firstName"
                                                       label="User's First Name" variant="outlined"
                                                       helperText="" value={firstName}
                                                       onChange={changeFirstName} onBlur={validateFirstNameField}
                                                       error={firstNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={lastNameError}>
                                            <TextField className="w-100" name="lastName" id="lastName" key="lastName"
                                                       label="User's Last Name" variant="outlined"
                                                       helperText="" value={lastName}
                                                       onChange={changeLastName} onBlur={validateLastNameField}
                                                       error={lastNameError} required/>
                                        </InputWithErrorMessage>
                                    </div>
                                    {
                                        user.roles && user.roles.indexOf(SYS_ADMIN) !== -1 &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={organizationIdError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100"
                                                        name="organizationId" id="organizationId" key="organizationId"
                                                        label="Organization" value={organizationId} variant="outlined"
                                                        onChange={changeOrganization} onBlur={validateOrganizationField}
                                                        error={organizationIdError}>
                                                    <option value={0}>Please select an organization
                                                    </option>
                                                    {
                                                        organizationList?.map((item) => (
                                                            <option value={item.organizationId}>{item.orgName}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    {
                                        diagnosisList && diagnosisList.length > 0 && user.roles && (user.roles.indexOf(SYS_ADMIN) !== -1 || user.roles.indexOf(ORGANIZATION_ADMIN) !== -1 || user.roles.indexOf(DEPARTMENT_ADMIN) !== -1) &&
                                        <div className="formInput">
                                            <InputWithErrorMessage errorMessage={diagnosesError}>
                                                <Select multiple={false} native={true} autoWidth={true}
                                                        className="w-100"
                                                        name="diagnosis" id="diagnosis" key="diagnosis"
                                                        label="Diagnosis"
                                                        value={diagnoses && diagnoses.length > 0 ? diagnoses[0] : 0}
                                                        variant="outlined"
                                                        onChange={changeDiagnosis} onBlur={validateDiagnosisField}>
                                                    <option value={0}>Please select a diagnosis
                                                    </option>
                                                    {
                                                        (diagnosisList && diagnosisList.length > 0 ? diagnosisList?.map((item) => (
                                                            <option value={item.diagnosisId}>{item.name}</option>
                                                        )) : '')
                                                    }
                                                </Select>
                                            </InputWithErrorMessage>
                                        </div>
                                    }
                                    <div className="buttonContainer loginButtonContainer">
                                        <Button type="submit" variant="contained" className="loginUIComp center"
                                                onClick={handleClick}>
                                            Invite This User
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Container>
            </div>
            <div className="gradientBottom">
            </div>
        </div>
)
};

export default PatientInvite;
