import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_2_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin4">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fa51937b5666489cfde176c9f01bd78c.png" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#75B8E4",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Ways We Can Help</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">No Two Programs Alike</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph">
                    <p>
                      Your personal care plan is designed based on your detailed
                      assessment and evaluation and will empower you to use
                      lifestyle as medicine. Your care team includes medical
                      providers, therapists and a dietitian — experts in
                      musculoskeletal health and its link to general health.
                    </p>
                    <p>
                      <span className="bold italic legoMargin3">
                        Your coordinated care program may include:
                      </span>{" "}
                    </p>

                    {/* L53 Bullet List */}
                    <div className="lego l053 smallFont legoMargin2">
                      <ul className="w-100">
                        <li className="w-100 legoMargin1">
                          Behavioral health counseling
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8493/Services/Living-Well-Center/WashU-Living-Well-Center-Movement-and-Exercise-Program.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Exercise classes
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8445/Services/Living-Well-Center/Living-Well-Center-Long-Covid-Program.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Long COVID Program
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8358/Services/Living-Well-Center/Nutritional-Counseling.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Nutritional counseling
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">Physical therapy</li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8431/Services/Living-Well-Center/Shared-Medical-Appointments.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Shared medical appointments
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/6940/Services/Living-Well-Center/Smoking-Cessation.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Smoking cessation
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8357/Services/Living-Well-Center/Living-Well-Center-Weight-Loss-Program.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Weight loss
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End L53 Bullet List */}
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph">
                    <p>
                      <span className="bold italic legoMargin2">
                        For patients with select pain conditions, their care
                        plan also might include:
                      </span>{" "}
                    </p>

                    {/* L53 Bullet List */}
                    <div className="lego l053 smallFont legoMargin2">
                      <ul className="w-100">
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8326/Services/Living-Well-Center/Acupuncture.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Acupuncture*
                          </a>
                        </li>
                        <li className="w-100 legoMargin1">
                          <a
                            className="blueLinkfont18"
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8446/Services/Living-Well-Center/Medical-Massage-Therapy.aspx"
                            rel="noreferrer noopener"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Medical massage*
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* End L53 Bullet List */}
                    <p>
                      <span className="bold italic">*Acupuncture:</span> some
                      insurances might cover acupuncture services; please check
                      with your insurance carrier to verify coverage.{" "}
                      <span className="bold italic">Medical Massage</span> is
                      performed at STAR Physical Therapy. At this time they are
                      unable to bill this service to insurance.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066A legoMargin3">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps2">
                      MANY PROGRAMS TO CHOOSE FROM
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      As part of our services, the Living Well Center offers a
                      variety of virtual and in-person classes to help you
                      achieve optimal health and wellness.
                    </p>
                    <p>
                      <span className="bold">
                        Lifestyle as Medicine: Shared Medical Appointments
                      </span>{" "}
                      <br />
                      Learn how to use your lifestyle as medicine through
                      nutrition, exercise, sleep, stress reduction and health
                      behavior change.
                    </p>
                    <p>
                      <span className="bold ">Weight Loss</span> <br />
                      Our dietician-led weight loss shared medical appointments
                      are designed to help you drop excess weight using the
                      latest evidence-based approaches.
                    </p>
                    <p>
                      <span className="bold ">
                        Behavioral Health Counseling
                      </span>{" "}
                      <br />
                      Use your values and strengths to establish goals and
                      create healthy, sustainable habits in your everyday life.
                    </p>
                    <p>
                      <span className="bold ">Smoking Cessation Program</span>{" "}
                      <br />
                      Smoking is an addiction that can be overcome with
                      guidance, support and a committed mindset. Let us help you
                      quit smoking for good so you can live guilt-free.
                    </p>
                    <p>
                      <span className="bold ">Tai Chi Program</span> <br />
                      Find balance and improve strength with the Living Well
                      Center Tai Chi Program
                    </p>
                    <p>
                      <span className="bold ">Yoga Program</span> <br />
                      Yoga uses artful body movements and breath work to bring
                      deep relaxation to the body and improve sleep, digestion
                      and provide stress relief.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                        <YouTube videoId="umWIvIe9144" opts={opts} />
                      </div>

                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          How can Living Well Center help you?
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066A legoMargin3">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps2">
                      You can choose from multiple “tracks” to match your goals.
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin1">
                    <p>
                      The Living Well Center is designed to deliver a unique way
                      of treating you by addressing every aspect of your life
                      that can influence your success toward your goals. We also
                      provide multiple “tracks” that match the most common goals
                      our patients have. We have four specific tracks right now,
                      and will be adding more in the future.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L96 Content Block with Bullets and No Background Color */}
                  <div className="col-11 m-auto">
                    <div className="lego l096">
                      <div className="contentContainer">
                        {/* L53 Bullet List */}
                        <div className="lego l053 customBulletList">
                          <ul className="w-100">
                            <li className="w-100">
                              <p className="withBorderBottom">
                                One track is to{" "}
                                <span className="bold italic">
                                   optimize to surgery
                                </span>
                                . We help patients optimize their health become
                                a better candidate for needed surgery, and help
                                them do well with that surgery.
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* End L53 Bullet List */}
                        {/* L53 Bullet List */}
                        <div className="lego l053 customBulletList">
                          <ul className="w-100">
                            <li className="w-100">
                              <p className="withBorderBottom">
                                The second track is to
                                <span className="bold italic">
                                   target and treat a specific condition if a
                                  patient is not a candidate for surgery, 
                                </span>
                                creating better outcomes by other lifestyle
                                medicine means
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* End L53 Bullet List */}

                        {/* L53 Bullet List */}
                        <div className="lego l053 customBulletList">
                          <ul className="w-100">
                            <li className="w-100">
                              <p className="withBorderBottom">
                                Another track
                                <span className="bold italic">
                                  cancer survivorship 
                                </span>
                                where we help patients who have gone through a
                                cancer journey to optimize their current health
                                and long-term health.
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* End L53 Bullet List */}

                        {/* L53 Bullet List */}
                        <div className="lego l053 customBulletList">
                          <ul className="w-100">
                            <li className="w-100">
                              <p>
                                And a fourth track is
                                <span className="bold italic">
                                   health optimization.
                                </span>
                                That’s creating a plan and providing services
                                designed to optimize your health toward a
                                specific life goal you have, such as healthy
                                living after having a baby, training for an
                                athletic event or simply seeking to thrive as
                                you grow older.
                              </p>
                            </li>
                          </ul>
                        </div>
                        {/* End L53 Bullet List */}
                      </div>
                    </div>
                  </div>

                  {/* End L96 Content Block with Bullets and No Background Color */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_2_3;
