import { useLocation } from 'react-router-dom';
import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import styles from './TopNav.module.scss';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import {Navbar, Nav, Dropdown} from "react-bootstrap";
import logo from "../../../images/careorbitLogo.svg";
import {Avatar} from "@material-ui/core";
import {
    getUser
} from "../../../helpers/index.js";
import {userProfile} from "../../../actions/userProfile.js";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {logout} from "../../../middleware/auth/index.js";
import {useNavigate, useParams} from "react-router-dom";
import styled from 'styled-components'

import Select from "@material-ui/core/Select/Select.js";
import {DEPARTMENT_ADMIN, ORBIT_ADMIN, ORGANIZATION_ADMIN, REGULAR_USER, SYS_ADMIN} from "../../../constants/roles.js";
import {diagnosesList} from "../../../actions/diagnosisList.js";
import {diagList} from "../../../actions/diagList.js";
import {
  setDefaultOrbit,
  setDiagnosticFilterList,
  setDiagnosticId,
  setDiagnosticList,
  setDisplayDiagnostics,
  setDisplayTiles, setHome, setNavigateToHomePage,
  setOrbitList, setRefreshTileAttributeList
} from "../../../actions/home.js";
import {orbit} from "../../../actions/orbit.js";
import {orbitClusterTileList} from "../../../actions/orbitClusterTileList.js";
import {HOME, UPDATE_LOGO} from "../../../actions/types.js";
import {orbitById} from "../../../actions/orbitById.js";
//adding to force a push

const TopNav = () => {
  let user = JSON.parse(getUser());
  const [imageUrl, setImageUrl] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [orgProfileImage, setOrgProfileImage] = useState(null);
  const [imageSet, setImageSet] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isPatient, setIsPatient] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  let displayDropDowns = false;
  let selectedOrbit = '';
  let selectedDiagnosisId = [];
  const location = useLocation();

  const {
    diagnosticId,
    defaultOrbit,
    orbitList,
    diagnosticList,
    diagnosticFilterList,
    displayTiles,
    initialTopNavDisplay,
    navigateToHomePage
  } = useSelector(state => {
      user = JSON.parse(getUser());
      return state.home;
  });

  /*const {defaultOrbit} = useSelector(state => {
    user = JSON.parse(getUser());
    return state.orbit
  });*/
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeDiagnosis = (e) => {
    dispatch(setDisplayTiles(true));
    dispatch(setDisplayDiagnostics(false));
    dispatch(setNavigateToHomePage(true));
    dispatch(setRefreshTileAttributeList(true));
    dispatch(setDiagnosticId([e.target.value]));
    dispatch(setHome({
        tileAttributes: null,
        clusterCssArray: null,
        clusterType: 0,
        useFlexContainer: null
    }))
    user = JSON.parse(getUser());
    console.error('Here is 6');
  }

  const changeOrbit = async (e) => {
    //setOrbitId(e.target.value);
    dispatch(setDefaultOrbit([e.target.value]));
    dispatch(setDiagnosticFilterList([]));
    dispatch(setDiagnosticList([]));
    dispatch(setNavigateToHomePage(true));
    dispatch(setRefreshTileAttributeList(true));
    dispatch(setHome({
      tileAttributes: null,
      clusterCssArray: null,
      clusterType: 0,
      useFlexContainer: null
    }));
    const orb = await orbitById({orbitId: e.target.value}, async (data) => {
      //setList(data);
      dispatch({type: UPDATE_LOGO, payload: {orbitCssClass: data.cssClass}});
      let cssClassProps = document.querySelector("body");

      if (cssClassProps && data.cssClass && data.cssClass.length > 0)
        cssClassProps.className = 'newOrbitStructure contentPage safecare' + ' ' + data.cssClass.join(' ');

      navigate(location.pathname);
    });
    await dispatch(orb);
    console.error('Here is 6');
  }

  let {userProfileImage} = useSelector(state => {
    if (state.userProfile.userProfileImage && state.userProfile.userProfileImage !== imageUrl) {
      setImageUrl(state.userProfile.userProfileImage);
    }

    return state.userProfile
  });
  let {logoImageUrl} = useSelector(state => {
    if (!imageSet && state.logo.logoImageUrl && state.logo.logoImageUrl !== logo) {
      setOrgProfileImage(state.logo.logoImageUrl);
      setImageSet(true);
    }

    return state.logo
  });
  const Button = styled.button`
  background: ${props => "rgba(0, 0, 0, 0.6) !important"};
  color: ${props => "white"};
  color: ${props => "white"};
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
`;

  useEffect(async () => {
      if (user.roles[0] === ORGANIZATION_ADMIN ||
          user.roles[0] === SYS_ADMIN ||
          user.roles[0] === DEPARTMENT_ADMIN ||
          user.roles[0] === ORBIT_ADMIN)
          setIsAdmin(true);
      else
          setIsAdmin(false);

    const ca = await userProfile({}, (data) => {
      setImageUrl(data.imageUrl);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setIsPatient(data.isPatient);
      setOrgProfileImage(data.orgImageUrl ? data.orgImageUrl : logo);
      setLoaded(true);
    });
    await dispatch(ca);

    if ((user.roles[0] === DEPARTMENT_ADMIN ||
        user.roles[0] === ORBIT_ADMIN) && user.defaultOrbit && (!diagnosticFilterList || diagnosticFilterList.length === 0)) {

      const diagFilterList = await diagnosesList({orbitId: user.defaultOrbit}, async (data) => {
        dispatch(setDiagnosticFilterList(data));

        if (!displayTiles) {
          if (data.length === 0 || !Array.isArray(data)) {
            dispatch(setDisplayTiles(true));
            dispatch(setDisplayDiagnostics(false));
          } else {
            dispatch(setDisplayTiles(false));
            dispatch(setDisplayDiagnostics(true));
          }
        }
      });
      await dispatch(diagFilterList);
    }
    //get default orbit for logged in user
  }, []);


  useEffect(async () => {
    if (navigateToHomePage) {
      navigate('/');
      dispatch(setNavigateToHomePage(false));
    }
  }, [navigateToHomePage])

  useEffect(async () => {
    if ((user.roles[0] === ORGANIZATION_ADMIN ||
        user.roles[0] === SYS_ADMIN) && (!defaultOrbit || defaultOrbit.length === 0)) {

      dispatch(setDisplayTiles(false));
      const orb = await orbit({}, async (data) => {
        //setList(data);
        dispatch(setDiagnosticList([]));
        dispatch(setDiagnosticFilterList([]));
        dispatch(setOrbitList(data));
      });
      await dispatch(orb);
    }

    if ((user.roles[0] === ORGANIZATION_ADMIN ||
        user.roles[0] === SYS_ADMIN ||
        user.roles[0] === DEPARTMENT_ADMIN ||
        user.roles[0] === ORBIT_ADMIN) && (defaultOrbit && defaultOrbit?.length > 0)) {

      if (defaultOrbit && defaultOrbit.length > 0) {
        const diagFilterList = await diagnosesList({orbitId: defaultOrbit}, async (data) => {
          dispatch(setDiagnosticFilterList(data));

          if (!displayTiles) {
            if (data.length === 0 || !Array.isArray(data)) {
              dispatch(setDisplayTiles(true));
              dispatch(setDisplayDiagnostics(false));
            } else {
              dispatch(setDisplayTiles(false));
              dispatch(setDisplayDiagnostics(true));
            }
          }
        });
        await dispatch(diagFilterList);
      }
    }
    //get default orbit for logged in user
  }, [defaultOrbit]);

  useEffect(async () => {
    if (diagnosticFilterList && diagnosticFilterList.length > 0) {
      const dList = await diagList({filteredList: diagnosticFilterList}, async (data) => {
        dispatch(setDiagnosticList(data));
      });

      await dispatch(dList);
    }
  }, [diagnosticFilterList])

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch({
      type: 'RESET'
    });
    logout();
    navigate('/login');
  }

  displayDropDowns = ((defaultOrbit && defaultOrbit.length > 0) || ((user.roles[0] === ORBIT_ADMIN || user.roles[0] === DEPARTMENT_ADMIN) && user?.defaultOrbit));

  if (defaultOrbit && Array.isArray(defaultOrbit) && defaultOrbit[0])
    selectedOrbit = defaultOrbit[0];
  else if (defaultOrbit && defaultOrbit.length > 0)
    selectedOrbit = defaultOrbit;
  else
    selectedOrbit = orbitList[0];

  if (user.roles[0] === ORGANIZATION_ADMIN ||
      user.roles[0] === SYS_ADMIN ||
      user.roles[0] === ORBIT_ADMIN ||
      user.roles[0] === DEPARTMENT_ADMIN)
    selectedDiagnosisId = Array.isArray(diagnosticId) && diagnosticId.length > 0 ? diagnosticId : diagnosticId ? [diagnosticId] : user?.dIdList ? user?.dIdList : null;
  else
    selectedDiagnosisId = user?.dIdList;

  //const displayUserInvite = (initialTopNavDisplay && (user.roles[0] === ORBIT_ADMIN || user.roles[0] === DEPARTMENT_ADMIN || user.roles[0] === SYS_ADMIN || user.roles[0] === ORGANIZATION_ADMIN) || isPatient) && ((defaultOrbit && defaultOrbit.length > 0) || (user.defaultOrbit && user.defaultOrbit.length > 0));
  //const displayPatientInvite = (initialTopNavDisplay && (user.roles[0] === ORBIT_ADMIN || user.roles[0] === SYS_ADMIN || user.roles[0] === ORGANIZATION_ADMIN)) && (((defaultOrbit && defaultOrbit.length > 0) || (user.defaultOrbit && user.defaultOrbit.length > 0)));
  const displayUserInvite = ((user.roles[0] === ORBIT_ADMIN || user.roles[0] === DEPARTMENT_ADMIN || user.roles[0] === SYS_ADMIN || user.roles[0] === ORGANIZATION_ADMIN) || isPatient) && ((defaultOrbit && defaultOrbit.length > 0) || (user.defaultOrbit && user.defaultOrbit.length > 0));
  const displayPatientInvite = ((user.roles[0] === ORBIT_ADMIN || user.roles[0] === SYS_ADMIN || user.roles[0] === ORGANIZATION_ADMIN)) && (((defaultOrbit && defaultOrbit.length > 0) || (user.defaultOrbit && user.defaultOrbit.length > 0)));

  return (
      <div className={styles.Nav} key="navOuterContainer">
        <Navbar className="navbarLoggedIn"bg="white" variant="light" expand="lg">
          <Container className="navContainer" key="navNavBarContainer">
            <div className="navLogoContainer" key="navLogoContainer">
              <Navbar.Brand href="/">
                <img className="brandLogo" style={{"visibility": loaded ? "visible" : "hidden"}} alt="logo" src={orgProfileImage}/>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end loggedInNav">
              <Nav>
                { displayDropDowns && (user.roles[0] === ORGANIZATION_ADMIN || user.roles[0] === SYS_ADMIN || user.roles[0] === ORBIT_ADMIN || user.roles[0] === DEPARTMENT_ADMIN) &&
                    <span key="drpDownContainer" className="orbitAndDiagnosisSelectContainer">
                        {
                            (defaultOrbit && defaultOrbit?.length > 0 && orbitList && orbitList.length > 0) && (user.roles[0] === ORGANIZATION_ADMIN || user.roles[0] === SYS_ADMIN) &&
                              <Select multiple={false} native={true} autoWidth={true} className="orbitSelect" name="orbit"
                                      id="orbit" label="Orbit"
                                      value={selectedOrbit} variant="standard"
                                      key="orbitDrp"
                                      onChange={changeOrbit}>
                                  {
                                      orbitList.map((item, i) => (
                                          <option key={"orbitDrpListItem" + i} value={item.orbitId}>{item.orbitName}</option>
                                      ))
                                  }
                              </Select>
                        }
                        {
                            (((diagnosticId && diagnosticId.length > 0) || ((defaultOrbit && defaultOrbit?.length > 0) || ((user.roles[0] === ORBIT_ADMIN || user.roles[0] === DEPARTMENT_ADMIN) && user.defaultOrbit))) && diagnosticList && diagnosticList.length > 0) &&
                            <Select multiple={false} native={true} autoWidth={true} className="diagnosisSelect" name="diagnosis"
                                    id="diagnosis" label="Orbit"
                                    key="diagnosisDrp"
                                    value={selectedDiagnosisId} variant="standard"
                                    onChange={changeDiagnosis}>
                                {
                                    diagnosticList.map((item, i) => (
                                        <option key={"diagnosticDrpListItem" + i} value={item.diagnosisId}>{item.name}</option>
                                    ))
                                }
                            </Select>
                        }
                    </span>
                }
                <Nav.Link href="mailto:support@careorbit.health" className="text-dark">Help</Nav.Link>
                {
                  // isPatient && <Nav.Link className="text-dark" href="/userinvite">Invite Family</Nav.Link>
                }
				{
                  (initialTopNavDisplay && user.roles[0] === SYS_ADMIN) && <Nav.Link href="/generateQrCodeUrl" className="text-dark">QR Code URL</Nav.Link>
				}
                {
                  displayUserInvite && <Nav.Link className="text-dark" href="/userinvite">{isAdmin ? "Invite Admin" : "Invite Family"}</Nav.Link>
                }
                {
                  displayPatientInvite && <Nav.Link className="text-dark" href="/patientinvite">Invite Patient</Nav.Link>
                }
                {
                  user && user?.isTemporaryOrbitAccessUser ? <Nav.Link className="text-dark" href={"/register/tempuser/posttrial/" + ("true" + (user?.accessToken ? "/" + user?.accessToken : "") +  "" + (user?.counterToken ? "/" + user?.counterToken : "") + "" + (user?.hideRenew ? "/" + user?.hideRenew : "/false"))}>Register</Nav.Link> : ''
                }
                <Nav.Link className="text-dark" onClick={logoutUser}>
                  Logout
                </Nav.Link>
                {user && user?.isTemporaryOrbitAccessUser ?
                <div className="profileContainer" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <div className="avatarContainer" style={{height: 36}}>
                    <Avatar
                        alt={lastName + ', ' + firstName}
                        src={imageUrl}
                        name="previewImage"
                        id="previewImage"
                        className="previewImage"
                    />
                    <div className="userName" style={{marginBottom: "0px", color: "#000000"}}>
                      {firstName}
                    </div>
                  </div>
                </div> :
                <Nav.Link className="text-dark profileContainer" href="/profile" >
                  <div className="avatarContainer">
                    <Avatar
                        alt={lastName + ', ' + firstName}
                        src={imageUrl}
                        name="previewImage"
                        id="previewImage"
                        className="previewImage"
                    />
                    <div className="userName" style={{marginBottom: "0px"}}>
                      {firstName}
                    </div>
                  </div>
                </Nav.Link>
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
  );
};

export default TopNav;
