import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_4 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 legoMargin5 greenColorAndBorder">
                    <h1 className="tileTitle">Platelet-Rich Plasma</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin3">
                    <h2 className="subHeadUpperAndLowerCase2">
                      About the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L11 Image */}
                  <div className="lego l011 legoMargin3">
                    <img
                      className="imageW100"
                      style={{ maxHeight: "300px" }}
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6b346e0664e8979bea9d3fdde8ef0cb4.jpeg"
                    />
                  </div>
                  {/* End L11 Image */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      Platelet-Rich Plasma consists of two elements: plasma, the
                      liquid portion of blood, and platelets, a type of blood
                      cell that plays an important role in healing throughout
                      your body.
                    </p>
                    <p>
                      The Platelet-Rich Plasma (PRP) treatment is a step-by-step
                      therapeutic process where your own blood is drawn, your
                      platelets are concentrated, and then injected into the
                      injured area. Platelets contain growth factors and other
                      proteins that stimulate tissue repair and reduce
                      inflammation, which promotes the healing of many
                      musculoskeletal injuries and conditions.
                    </p>
                    <p className="legoMargin2">
                      PRP treatment is recommended when other traditional
                      conservative methods of treatment have failed, such as
                      physical therapy, rest, medications, and steroid
                      injections.
                    </p>
                    <p className="legoMargin3">
                      <span className="boldText">
                        What musculoskeletal injuries or conditions can PRP
                        treat?
                        <br />
                      </span>
                      PRP is effective in treating conditions involving tendons,
                      ligaments, and joints, such as Achilles tendinopathy,
                      patellar tendinopathy, tennis elbow, osteoarthritis,
                      rotator cuff injuries, plantar fasciitis and ligament
                      sprains. PRP efficacy may vary based on individual cases.{" "}
                    </p>
                    <p>
                      <span className="boldText">
                        What does the PRP procedure involve?
                      </span>
                      <br />
                      PRP is a minimally invasive injection, performed as an
                      outpatient procedure. The complete process typically takes
                      less than an hour. 
                    </p>
                    <p className="legoMargin2">
                      {/* L53 Numbered List */}
                      <div className="lego l053 smallFont">
                        <ol className="w-100">
                          <li>
                            A sample of blood is drawn from the patient's arm.
                          </li>
                          <li>
                            The sample of blood is then placed in a centrifuge
                            that spins at high speed, which separates platelets
                            from other blood cells.
                          </li>
                          <li>
                            The blood is then processed to collect the platelets
                            and mix them with anticoagulant to prevent blood
                            from clotting.
                          </li>
                          <li>
                            {" "}
                            Numbing medication may be applied sparingly around
                            the injection site to offset any discomfort or pain
                            from the injection. The portion of concentrated
                            platelets is then injected into the site of injury
                            with ultrasound guidance.
                          </li>
                        </ol>
                      </div>
                      {/* End L53 Numbered List */}
                    </p>
                    <p>
                      <span className="boldText">
                        What are the risks of a PRP injection?
                      </span>{" "}
                      <br />A PRP injection is a low-risk procedure and does not
                      usually cause major side effects. Less common risks
                      include:
                    </p>
                    <p>
                      Allergic reaction
                      <br />
                      Bleeding
                      <br />
                      Infection
                      <br />
                      Nerve injuries
                      <br />
                      Tissue damage  <br /> <br />
                    </p>
                    <p className="legoMargin1">
                      Will insurance pay for the PRP procedure? <br />
                    </p>
                    <p>
                      Currently, the FDA considers PRP investigational for many
                      of the musculoskeletal injuries being referred. Medicare
                      currently only covers PRP for chronic wound
                      treatment. Most insurance plans do not cover the cost of
                      PRP, making the patient responsible for
                      payment. Therefore, Washington University requires payment
                      in full prior to the service being performed. Insurance
                      plans will be billed upon request and if the service is
                      determined to be covered, a refund will be provided to the
                      patient.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin1">
                    <h2 className="subHeadUpperAndLowerCase2">
                      Prior to the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      Two weeks before your PRP injection, stop all
                      anti-inflammatory medications such as Motrin, Meloxicam,
                      Ibuprofen, Advil, Aleve, etc.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin1">
                    <h2 className="subHeadUpperAndLowerCase2">
                      After the Procedure.
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L53 Bullet List */}
                  <div className="lego l053 smallFont">
                    <ul className="w-100">
                      <li className="w-100 legoMargin1">
                        After your PRP injection, you will be given personalized
                        instructions.
                      </li>
                      <li className="w-100 legoMargin1">
                        Stay hydrated and eat healthy, nourishing meals. 
                      </li>
                      <li className="w-100  legoMargin1">
                        Do not take a bath, swim, or use a hot tub for 48 hours.{" "}
                        You may shower.
                      </li>
                      <li className="w-100 legoMargin1">
                        Do not use anti-inflammatory medications for two weeks
                        after your procedure. Instead, use acetaminophen
                        (Tylenol) to lower your pain.
                      </li>
                      <li className="w-100 legoMargin1">
                        Minimize activity and ice the affected area for 15-20
                        minutes every 2-4 hours for the first few days after the
                        injection.{" "}
                      </li>
                      <li className="w-100 legoMargin1">
                        For 2 weeks after procedure, avoid strenuous activity
                        and exercises at the site of the injection.
                      </li>

                      <li className="w-100 legoMargin1">
                        The joint maybe immobilized by using a sling or walking
                        boot for 2 weeks after your procedure.
                      </li>
                      <li className="w-100">
                        Physical therapy or rehabilitation exercise program is
                        initiated 2 weeks after your procedure.. Patients
                        typically return to normal activity after about 6 weeks,
                        this return to activity will be guided by your doctor
                        and therapist.
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003 legoMargin1">
                    <h2 className="subHeadUpperAndLowerCase3">
                      Recovering from your PRP procedure
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      The first few days following the injection is known as the
                      inflammatory phase. Some people may feel an increase in
                      pain during this time.
                      <br />
                      It may take 6-8 weeks to assess the body's response to the
                      treatment. Full recovery may take 3-6 months, with tendon
                      injuries usually requiring a longer recovery time. <br />
                      You will have a follow-up appointment with your team at 6
                      weeks and 3 months to check your progress.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L82? Content 2 sides 50% width */}
                  <div className="lego l082 legoMargin3">
                    <div className="contentLeftSide">
                      {/* L4 Subhead Upper */}
                      <div className="lego l004 legoMargin2">
                        <h2 className="subHeadAllCaps700">
                          For Soft Tissue/Tendon Areas
                        </h2>
                        <h2
                          className="subHeadAllCaps700"
                          style={{ textTransform: "lowercase" }}
                        >
                          (like muscles and tendons)
                        </h2>
                      </div>
                      <div className="lego l00paragraph">
                        <p className="legoMargin2">
                          <span className="boldItalic">Days 0-3:</span>
                          <br />
                          Take it easy, avoid putting too much weight on the
                          treated area, rest, and manage any pain.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Days 4-14:</span>
                          <br />
                          Start moving gently, do light activities, and consider
                          low-impact exercises like biking or swimming if it
                          doesn't hurt much. You can also work on other parts of
                          your body.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Week 2-6:</span>
                          <br />
                          Begin formal physical therapy, and start lifting light
                          weights. Soft tissue mobilization is okay if your pain
                          is less than 3/10.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Week 6-12:</span>
                          <br />
                          Introduce a bit more intense exercise, like heavy
                          weights and energetic movements, if your pain remains
                          low. Increase the activities that put weight on the
                          treated area as long as it doesn't cause much pain.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">
                            Week 12 and beyond:
                          </span>
                          <br />
                          If you've improved by more than 75%, go ahead and
                          resume all regular activities. If not, think about
                          getting another injection. It might take more than 6
                          months to feel the full effect.
                        </p>
                      </div>
                      {/* End L4 Subhead Upper */}
                    </div>
                    <div className="contentRightSide">
                      {/* L4 Subhead Upper */}
                      <div className="lego l004 legoMargin4">
                        <h2 className="subHeadAllCaps900">For Joints</h2>
                      </div>
                      {/* End L4 Subhead Upper */}

                      <div className="lego l00paragraph">
                        <p className="legoMargin2">
                          <span className="boldItalic">Days 0-3:</span>
                          <br />
                          Take it easy, avoid putting too much weight on the
                          treated area, rest, and manage any pain.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Days 4-14:</span>
                          <br />
                          Start with light activities that use your body weight
                          as much as feels okay. Stick to gentle exercises.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Week 2-4:</span>
                          <br />
                          Increase the intensity a bit, try more challenging
                          exercises, and slowly start activities where your
                          limbs move freely. Include exercises to improve your
                          sense of the treated area's position.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Week 4-6:</span>
                          <br />
                          Increase the impact of your activities, and if you
                          play sports, consider returning to them as long as it
                          doesn't cause much pain.
                        </p>
                        <p className="legoMargin2">
                          <span className="boldItalic">Week 6 and beyond:</span>
                          <br />
                          Get a check-up at this point. If you haven't
                          experienced at least 75% pain relief or aren't getting
                          better, we might need to explore other options.Take it
                          easy, avoid putting too much weight on the treated
                          area, rest, and manage any pain.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* End L82? Content 2 sides 50% width */}

                  {/* L85 Color Bar Top Content Block */}
                  <div
                    className="lego l085 legoMargin3"
                    style={{ width: "100%" }}
                  >
                    <div className="headerColorBar">
                      <h3>Specific Tendon Restrictions (if applicable)</h3>
                    </div>
                    <div className="contentContainer">
                      <p className="legoMargin2">
                        <span className="boldItalic">
                          Patellar tendon, quadriceps tendon, gluteal tendons,
                          hamstring tendon:
                        </span>
                        <br />
                        Avoid putting more than half your weight on it for three
                        days. Stick to low-impact activities for two weeks.
                      </p>
                      <p className="legoMargin2">
                        <span className="boldItalic">
                          Achilles tendon, plantar fascia:
                        </span>
                        <br />
                        For the first three days, don't put more than half your
                        weight on it, and use a boot. After that, use the boot
                        as needed for two weeks.
                      </p>
                      <p className="legoMargin2">
                        <span className="boldItalic">
                          Lateral epicondyle, medial epicondyle:
                        </span>
                        <br />
                        Use a wrist brace for three days, then as needed for two
                        weeks.
                      </p>
                      <p className="legoMargin2">
                        <span className="boldItalic">Rotator cuff:</span>
                        <br />
                        Wear a shoulder sling for three days, then as needed for
                        two weeks.
                      </p>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  <div className="col-10 m-auto">
                    {/* L10A Bold Lead In */}
                    <div className="lego l00paragraph  legoMargin1">
                      <p className="boldLeadInNewUI">Follow-Up Appointments</p>
                    </div>
                    {/* End L10A Bold Lead In */}

                    {/* L53 Bullet List */}
                    <div className="lego l053 smallFont  legoMargin5">
                      <ul className="w-100">
                        <li className="w-100">
                          Call our office{" "}
                          <span className="boldItalic500"> (314-273-1832)</span>{" "}
                          to check-in or return for a follow-up visit 6 weeks
                          after your procedure
                        </li>
                        <li className="w-100">
                          Plan another check-in at 12 weeks after your procedure
                        </li>
                      </ul>
                    </div>
                    {/* End L53 Bullet List */}
                  </div>

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_4;
