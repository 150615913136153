import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport6 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 yellowColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Patient Portal & Forms</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L11B Image Max Width 300px */}
                  <div className="lego l011  d-flex justify-content-center">
                    <img
                      className="imageW100 maxWidth400"
                      alt="MyChart logo"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b61c5709d9e07f76d091237be0d7e36c.png"
                    />
                  </div>
                  {/* End L11B Image Max Width 300px */}

                  <div className="col-8 m-auto">
                    {/* L14 Paragraph */}
                    <div className="lego l00paragraph legoMargin3">
                      <p>
                        MyChart is a secure online tool that allows you to
                        manage your personal health information.
                      </p>
                    </div>
                    {/* End L14 Paragraph */}

                    {/* L4 Subhead Upper */}
                    <div className="lego l004 legoMargin2">
                      <h2 className="subHeadAllCaps800">
                        With MyChart, you can:{" "}
                      </h2>
                    </div>
                    {/* End L4 Subhead Upper */}

                    {/* L82? Content 2 sides 50% width */}
                    <div className="lego l082">
                      <div className="contentLeftSide">
                        <div className="lego l053 smallFont">
                          <ul className="w-100">
                            <li className="w-100">Manage your appointments</li>
                            <li className="w-100">
                              <a
                                href="https://www.ortho.wustl.edu/content/Patient-Forms/3694/Patient-Portal.aspx"
                                target="_blank"
                              >
                                Visit with your doctor virtually
                              </a>
                            </li>
                            <li className="w-100">
                              Complete your forms and questionnaires
                            </li>
                            <li className="w-100">View your test results</li>
                          </ul>
                        </div>
                      </div>
                      <div className="contentRightSide">
                        <div className="lego l053 smallFont">
                          <ul className="w-100">
                            <li className="w-100">View your test results</li>
                            <li className="w-100">
                              Communicate with your doctor
                            </li>
                            <li className="w-100">Pay bills online</li>
                            <li className="w-100">
                              Request prescription refills 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End L82? Content 2 sides 50% width */}

                    {/* L14 Paragraph */}
                    <div className="lego l00paragraph">
                      <p>
                        <span className="boldLeadInNewUI">
                          Go to more information on{" "}
                          <a
                            href="https://www.ortho.wustl.edu/content/Patient-Care/8295/Appointment-Information/Virtual-Visits-for-Orthopedic-Care.aspx"
                            target="_blank"
                          >
                            MyChart
                          </a>
                        </span>
                      </p>
                    </div>
                    {/* End L14 Paragraph */}
                  </div>

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L82? Content 2 sides 50% width */}
                  <div className="lego l082A legoMargin5">
                    <div className="contentLeftSide">
                      {" "}
                      {/* L101 Icon Color Bar New */}
                      <div className="lego l093B legoMargin1">
                        <div className="iconContainer">
                          <img
                            className="icon"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0020-review-icon.svg"
                          />
                        </div>
                        <div className="contentContainer">
                          <p>
                            <span className="boldHeadLine">Patient Forms</span>
                            <br />
                            Whether you’re a new, past or current patient, you
                            can secure necessary forms here
                          </p>

                          <div className="rectanglebox">
                            <p>Get forms &gt;</p>
                          </div>
                        </div>
                      </div>
                      {/* End L101 Icon Color Bar New */}
                    </div>
                    <div className="contentRightSide">
                      {" "}
                      {/* L101 Icon Color Bar New */}
                      <div className="lego l093B legoMargin1">
                        <div className="iconContainer">
                          <img
                            className="icon"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/K0050_summary-icon.svg"
                          />
                        </div>
                        <div className="contentContainer">
                          <p>
                            <span className="boldHeadLine">
                              Your Medical Records
                            </span>
                            <br />
                            Do you need a copy of your medical records
                            <br />
                            <span className="subtext">
                              Get more information and download the required
                              medical release forms here.
                            </span>
                            <div className="rectanglebox">
                              <p>Get records &gt;</p>
                            </div>
                          </p>
                        </div>
                      </div>
                      {/* End L101 Icon Color Bar New */}
                    </div>
                  </div>
                  {/* End L82? Content 2 sides 50% width */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport6;
