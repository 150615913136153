import React from 'react';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import '@fortawesome/fontawesome-svg-core/styles.css';
import {HashLink as Link} from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_3_2 = () => {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    }

    return (
        <div>
            <div className="w-100">
                <Container>
                    <div className="w-100">
                        <div className="col-10 m-auto">
                            <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                                <section>
                                    {/* L1 Tile Title Header */}
                                    <div className="lego l001 greenColorAndBorder legoMargin5">
                                        <h1 className="tileTitle">Diagnostic Spinal Injection</h1>
                                        <hr className="w-100 l001Rule"/>
                                    </div>
                                    {/* End Tile Title Header L1 */}

                                    {/* L2 */}
                                    <div className="lego l002 legoMargin3">
                                        <h2 className="l2Headline">About the Procedure.</h2>
                                    </div>
                                    {/* End L2 */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>A local anesthetic (numbing medication) will be injected into the space
                                            around the nerves (epidural space). The anesthetic typically numbs the area
                                            for 1-2 hours, and may provide some immediate relief.
                                        </p>

                                        <p>This procedure uses a contrast agent, which is a safe substance that makes a
                                            part of your body show up more clearly on an X-ray to ensure proper
                                            placement of the medicine.
                                        </p>

                                        <p>Should you have any questions regarding your procedure, contact our office
                                            at <span className="boldItalic"><a className="phoneLink" href="tel:314-273-1832">314-273-1832</a>.</span></p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L11 Image */}
                                    <div className="lego l011 legoMargin5">
                                        <img className="imageW100" alt=""
                                             src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/26c9088ea4ad16f5415fe8cfda96dfc7.png"/>
                                    </div>

                                    {/* End L11 Image */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">PRIOR TO THE PROCEDURE:</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin5">
                                        <p className="legoMargin2"><span
                                            className="bold">Allergies to x-ray contrast dye or local anesthetics:</span><br/>
                                            Alert our office if you have any history of allergies to local anesthetics
                                            (such as Novocain or
                                            lidocaine) or x-ray contrast dye
                                        </p>

                                        <p><span
                                            className="bold">Certain medications must be stopped prior to receiving a spinal injection.</span><br/>
                                            Please review the list below and follow the instructions for stopping that
                                            medication. </p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin2">
                                        <h2 className="subHeadAllCaps">Medication to be stopped (days prior to
                                            injection):</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L14 Paragraph */}
                                    <div className="lego l00paragraph legoMargin3">
                                        <p>If you are taking any of the medications listed below, check with your
                                            prescribing physician (primary care physician or specialist) about stopping
                                            the medication as detailed below. In the event your physician doesn't want
                                            medications stopped, contact our office immediately.</p>
                                    </div>
                                    {/* End L14 Paragraph */}

                                    {/* L85 Color Bar Top Content Block */}
                                    <div className="lego l085">
                                        <div className="headerColorBar">
                                            <h3>Consult your physician:</h3>
                                        </div>
                                        <div className="contentContainer">
                                            <p>Consult your physician: If you are taking any of the medications listed
                                                below, check with your prescribing physician (primary care physician or
                                                specialist) for directions on stopping the medication prior to your
                                                injection.
                                                In the event your physician doesn't want medications stopped, contact
                                                our office immediately.</p>
                                            <ul>
                                                <li><span className="bold">Aspirin</span></li>
                                                <li><span className="bold">Coumadin</span> (Warfarin)</li>
                                                <li><span className="bold">Eliquis</span> (Apixaban)</li>
                                                <li><span className="bold">NSAIDs</span> — non-steroid anti-inflammatory drugs such as: Motrin, Aleve,
                                                    Ibuprofen, Meloxicam, Diclofenac, Ketorolac, etc.
                                                </li>
                                                <li><span className="bold">Plavix</span> (Clopidogrel)</li>
                                                <li><span className="bold">Pradaxa</span> (Dabigatran)</li>
                                                <li><span className="bold">Xarelto</span> (Rivaroxaban)</li>
                                                <li><span className="bold">Persantine</span> (Dipyridamole)</li>
                                                <li><span className="bold">Arixtra</span> (Fondaparinux)</li>
                                                <li><span className="bold">Brilinta</span> (Ticagrelor)</li>
                                                <li><span className="bold">Effient</span> (Prasugrel)</li>
                                                <li><span className="bold">Pletal</span> (Cilostazol)</li>
                                                <li><span className="bold">Savaysa</span> (Edoxaban)</li>
                                                <li><span className="bold">Aggregnox</span> (Aspirin and Dipyridamole)</li>
                                                <li><span className="bold">Heparin</span></li>
                                                <li><span className="bold">Lovenox</span> (Enoxaparin)</li>
                                                <li><span className="bold">Fragmin</span> (Dalteparin)</li>
                                            </ul>
                                            <p><span className="bold italic">Do NOT stop any other medication</span> (blood
                                                pressure meds, diabetes meds,
                                                etc.) not listed above.</p>
                                        </div>
                                    </div>
                                    {/* End L85 Color Bar Top Content Block */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">DAY OF THE PROCEDURE:</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L83 Icon Color Bar New */}
                                    <div className="lego l083 legoMargin2">
                                        <div className="iconContainer">
                                            <img className="icon"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"/>
                                        </div>
                                        <div className="contentContainer">
                                            <p><span className="bold">Please arrive 30 minutes prior to your injection time for registration.</span>
                                                &nbsp;If you arrive more than 15 minutes past your scheduled injection
                                                time, your procedure may need to be rescheduled. </p>
                                        </div>
                                    </div>
                                    {/* End L83 Icon Color Bar New */}

                                    {/* L83 Icon Color Bar New */}
                                    <div className="lego l083 legoMargin5">
                                        <div className="iconContainer">
                                            <img className="icon"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_driving.svg"/>

                                        </div>
                                        <div className="contentContainer">
                                            <p><span
                                                className="boldItalic">Driving:</span> For your safety,&nbsp;<span
                                                className="boldItalic">we strongly recommend having someone drive you.</span>
                                                &nbsp;The anesthetic may cause temporary numbness.</p>
                                        </div>
                                    </div>
                                    {/* End L83 Icon Color Bar New */}

                                    {/* L4 Subhead Upper */}
                                    <div className="lego l004 legoMargin3">
                                        <h2 className="subHeadAllCaps">AFTER THE PROCEDURE:</h2>
                                    </div>
                                    {/* End L4 Subhead Upper */}

                                    {/* L83 Icon Color Bar New */}
                                    <div className="lego l083 legoMargin2">
                                        <div className="iconContainer">
                                            <img className="icon"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"/>
                                        </div>
                                        <div className="contentContainer">
                                            <p>You may&nbsp;<span className="bold italic">resume all medications the day after the procedure</span>,
                                                &nbsp;including blood thinners/anti-coagulants.</p>
                                        </div>
                                    </div>
                                    {/* End L83 Icon Color Bar New */}

                                    {/* L83 Icon Color Bar New */}
                                    <div className="lego l083">
                                        <div className="iconContainer">
                                            <img className="icon"
                                                 src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"/>
                                        </div>
                                        <div className="contentContainer">
                                            <p>You will be given written discharge instructions after your procedure
                                                along with a pain diary to track your response to the injection.</p>
                                        </div>
                                    </div>
                                    {/* End L83 Icon Color Bar New */}

                                    {/* L66 Divider Rule */}
                                    <div className="lego l066">
                                        <hr className="horizonalRule"/>
                                    </div>
                                    {/* End L66 Divider Rule */}

                                    {/* L84 Block */}
                                    <div className="lego l084 legoMargin5">
                                        <h3 className="contentTitle">Billing for Injection Procedures</h3>
                                        <p>Our Washington University orthopedic specialists treat patients at BJC
                                            HealthCare facilities, which means you may receive two separate bills. One
                                            bill is for the physician and the other is for the facility charges. </p>

                                        {/* L82? Content 2 sides 50% width */}
                                        <div className="lego l082 p-0">
                                            <div className="contentLeftSide">
                                                {/* L11 Image */}
                                                <div className="lego l011 legoMargin3">
                                                    <img className="imageW100 noBorderRadius"
                                                         src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"/>
                                                </div>
                                                {/* End L11 Image */}

                                            </div>
                                            <div className="contentRightSide">
                                                <div className="lego l00paragraph legoMargin1">
                                                    <p>If you have questions regarding a price estimation for the
                                                        services or a recently received bill, please contact:</p>
                                                    <p>BJC HealthCare Price Estimation:<br/>
                                                        <span
                                                            className="bold"><a className="phoneLink" href="tel:3147478845">314-747-8845</a> or toll free <a className="phoneLink" href="tel:18447478845">844-747-8845</a></span>
                                                    </p>
                                                    <p>Washington University Patient Services:<br/>
                                                        <span
                                                            className="bold"><a className="phoneLink" href="tel:3142730500">314-273-0500</a> or toll free <a className="phoneLink" href="tel:18008629980">800-862-9980</a></span>
                                                    </p>
                                                    <p>BJC HealthCare Patient Billing Services:<br/>
                                                        <span
                                                            className="bold"><a className="phoneLink" href="tel:3143628400">314-362-8400</a> or toll free <a className="phoneLink" href="tel:18553628400">855-362-8400</a></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End L82? Content 2 sides 50% width */}

                                    </div>
                                    {/* End L84 Block */}


                                    <div className="col-12 backToTop">
                                        <div className="w-100">
                                            <p><a className="specialUseBackToTop"
                                                  onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>BACK TO
                                                TOP </a></p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
};

export default InjectionSupport2_3_2;