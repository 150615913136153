import { combineReducers } from "redux";
import { authReducer } from "./authReducer.js";
import { currentUserReducer } from "./currentUserReducers";
import { roleReducer } from "./roleReducers";
import { forgotPasswordReducer } from "./forgotPasswordReducer.js";
import {registerReducer} from "./registerReducer.js";
import {homeReducer} from "./homeReducer.js";
import {userProfileReducer} from "./userProfileReducer.js";
import {orbitClusterReducer} from "./orbitClusterReducer.js";
import {registerInfoReducer} from './registerInfoReducer.js'
import {tilePageReducer} from "./tilePagReducer.js";
import {logoReducer} from "./logoReducers.js";
import {orbitReducer} from "./orbitReducer.js";
import {pageAccessReducer} from "./pageAccessReducer.js";
import {videoAccessReducer} from "./videoAccessReducer.js";

const rootReducer = combineReducers({
    auth: authReducer,
    role: roleReducer,
    user: currentUserReducer,
    forgotPassword: forgotPasswordReducer,
    register: registerReducer,
    home: homeReducer,
    userProfile: userProfileReducer,
    orbitCluster: orbitClusterReducer,
    registerInfo: registerInfoReducer,
    tilePage: tilePageReducer,
    logo: logoReducer,
    orbit: orbitReducer,
    pageAccess: pageAccessReducer,
    videoAccess: videoAccessReducer
});

const resetEnhancer = rootReducer => (state, action) => {
    if (action?.type !== 'RESET') return rootReducer(state, action);

    const newState = rootReducer(undefined, {});
    newState.router = state.router;
    return newState;
};

export {
    rootReducer,
    resetEnhancer
};
