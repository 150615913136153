import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_3_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-10 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">Epidural Steroid Injection</h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">About the Procedure.</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      A mixture of a cortisone and local anesthetic (numbing
                      medication) will be injected into the space around the
                      nerves (epidural space). The anesthetic typically numbs
                      the area for 1-2 hours and may provide some immediate
                      relief. The cortisone medication usually takes 3-5 days to
                      start to take effect and up to two weeks to get the full
                      benefit.
                    </p>

                    <p>
                      This procedure uses a contrast agent, which is a safe
                      substance that makes a part of your body show up more
                      clearly on an X-ray to ensure proper placement of the
                      medicine.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L11 Image */}
                  <div className="lego l011">
                    <img
                      className="imageW100"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/26c9088ea4ad16f5415fe8cfda96dfc7.png"
                    />
                  </div>

                  {/* End L11 Image */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">BEFORE THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin3">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_meds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      {/* L14 Paragraph */}
                      <div className="lego l00paragraph legoMargin3">
                        <p>
                          Certain medications* like antiplatelet and
                          anticoagulants{" "}
                          <span className="bold">must be stopped prior</span> to
                          receiving a spinal injection.{" "}
                          <span className="bold italic">
                            Please consult with the prescribing provider
                          </span>{" "}
                          for directions on stopping prior to your injection. In
                          the event your healthcare provider doesn't want
                          medications stopped, contact our office immediately.{" "}
                        </p>

                        <p>
                          <span className="note">
                            *Ibuprofen, Meloxicam, Diclofenac, etc., Plavix
                            (Clopidogrel), Pradaxa (Dabigatran), Xarelto
                            (Rivaroxaban), Acenocoumarol, Aggregnox, Persantine
                            (Dipyridamole), Arixtra (Fondaparinux), Brilinta
                            (Ticagrelor), Effient (Prasugrel), Fragmin
                            (Dalteparin), Heparin, Lovenox (Enoxaparin), Pletal
                            (Cilostazol), Savaysa (Edoxaban).
                          </span>
                        </p>
                      </div>
                      {/* End L14 Paragraph */}

                      {/* L53 Bullet List */}
                      <div className="lego l053">
                        <ul className="w-100">
                          <li className="w-100">
                            <span className="boldItalic">
                              Do NOT stop any other medication
                            </span>{" "}
                            (blood pressure meds, diabetes meds, etc.)
                          </li>
                          <li className="w-100">
                            <span className="boldItalic">
                              Alert our office if you have any history of
                              allergies to local anesthetics
                            </span>{" "}
                            (such as Novocain or lidocaine).
                          </li>
                          <li className="w-100">
                            If you have an{" "}
                            <span className="boldItalic">
                              allergy to X-ray contrast dye
                            </span>
                            , please notify your healthcare provider prior to
                            the procedure.
                          </li>
                        </ul>
                      </div>
                      {/* End L53 Bullet List */}
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">DAY OF THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Please arrive 30 minutes prior to your injection time
                          for registration.
                        </span>
                        &nbsp;If you arrive more than 15 minutes past your
                        scheduled injection time, your procedure may need to be
                        rescheduled.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">AFTER THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        You may&nbsp;
                        <span className="bold italic">
                          resume all medications the day after the procedure
                        </span>
                        , &nbsp;including blood thinners/anti-coagulants.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_writ_instruct.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        You will be given written discharge instructions after
                        your procedure along with a pain diary to track your
                        response to the injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_bath.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        Stay out of a pool, bath or hot tub for two days after
                        the procedure.
                        <br />
                        <span className="boldItalic">Showers are OK.</span>
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin3">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_driving.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldItalic">
                          Please consider having someone drive you.
                        </span>
                        <br />
                        The anesthetic may cause temporary numbness.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L85 Color Bar Top Content Block */}
                  <div className="lego l085">
                    <div className="headerColorBar">
                      <h3>Vaccinations and your Steroid Injection</h3>
                    </div>
                    <div className="contentContainer">
                      <p>
                        To ensure effectiveness of your COVID-19 vaccine,
                        COVID-19 booster, Flu vaccine, and any other vaccine and
                        to improve your ability to monitor potential side
                        effects,{" "}
                        <span className="boldItalic">
                          you should speak with your provider if:
                        </span>
                      </p>
                      <ul>
                        <li>You had the vaccine in the last 2 weeks.</li>
                        <li>You plan to have the vaccine.</li>
                        <li>You are immunocompromised.</li>
                        <li>
                          If you have any questions regarding your procedure,
                          contact your ordering provider’s office.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}

                  {/* L84 Block */}
                  <div className="lego l084 legoMargin5">
                    <h3 className="contentTitle">
                      Billing for Injection Procedures
                    </h3>
                    <p>
                      Our Washington University orthopedic specialists treat
                      patients at BJC HealthCare facilities, which means you may
                      receive two separate bills. One bill is for the physician
                      and the other is for the facility charges.{" "}
                    </p>

                    {/* L82? Content 2 sides 50% width */}
                    <div className="lego l082 p-0">
                      <div className="contentLeftSide">
                        {/* L11 Image */}
                        <div className="lego l011 legoMargin3">
                          <img
                            className="imageW100 noBorderRadius"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"
                          />
                        </div>
                        {/* End L11 Image */}
                      </div>
                      <div className="contentRightSide">
                        <div className="lego l00paragraph legoMargin1">
                          <p>
                            If you have questions regarding a price estimation
                            for the services or a recently received bill, please
                            contact:
                          </p>
                          <p>
                            BJC HealthCare Price Estimation:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3147478845">
                                314-747-8845
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18447478845">
                                844-747-8845
                              </a>
                            </span>
                          </p>
                          <p>
                            Washington University Patient Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3142730500">
                                314-273-0500
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18008629980">
                                800-862-9980
                              </a>
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Patient Billing Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3143628400">
                                314-362-8400
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18553628400">
                                855-362-8400
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End L82? Content 2 sides 50% width */}
                  </div>
                  {/* End L84 Block */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_3_1;
