import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {loginUser} from "../../../actions/login.js";
import {setLoginScreenStatus} from "../../../helpers/index.js";
import {useDispatch, useSelector} from "react-redux";
import {loginHersUser} from "../../../actions/loginHers.js";
import Container from "react-bootstrap/Container";
import Login from "../login/index.js";

const LoginHersUser = (props) => {
    const {orbitIntegrationCode} = useParams();
    const { errorMessage } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage);
        }
    }, [errorMessage]);

    useEffect(async () => {
        if (orbitIntegrationCode && orbitIntegrationCode.length > 0) {
            const login = await loginHersUser({ orbitIntegrationCode }, () => {
                setLoginScreenStatus(false);
                navigate("/");
            });
            await dispatch(login);
        }
    }, [])

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        <h1 style={{textAlign: "center", margin: "0px auto", display: "block"}}>You will be redirected once once membership is confirmed.......</h1>
                    </div>
                </Container>
            </div>
        </div>
    )
}


export default LoginHersUser;
