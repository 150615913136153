import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../../../actions/register.js";
import {InputWithErrorMessage} from "../../forms/inputWithErrorMessage.js";
import {InputAdornment, IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {SEND_INVITE, REGISTER} from './constants.js';
import { ToastContainer, toast } from 'react-toastify';

import {
    validateConfirmPassword,
    validateEmailAddress,
    validateFirstName,
    validateLastName,
    validatePassword,
    validatePhoneNumber,
    validateUserName
} from "./validation.js";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {registerInfo} from "../../../actions/registerInfo.js";
import {logout} from "../../../middleware/auth/index.js";
import {registerFromQrCode} from '../../../actions/registerFromQrCode';
import {Modal} from "react-bootstrap";
import {getUser, setLoginScreenStatus} from "../../../helpers/index.js";
import {registerFromQrCodeTempUser} from "../../../actions/registerFromQrCodeTempUser.js";
import {inviteTempUser} from "../../../actions/inviteTempUser.js";
import {AppContext} from "../../../context/AppContext.js";

const RegisterForTempUser = () => {
    const [userName, setUserName] = useState("");
    const [firstName, setFirstName] = useState(""); //bottomFormFirstName
    const [bottomFormFirstName, setBottomFormFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [bottomFormEmailAddress, setBottomFormEmailAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [bottomFormFirstNameError, setBottomFormFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [emailAddressError, setEmailAddressError] = useState("");
    const [bottomFormEmailAddressError, setBottomFormEmailAddressError] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [activationHeadingText, setActivationHeadingText] = useState("");
    const [isDirty, setIsDirty] = useState(false);
    const [isDirtyBottomForm, setIsDirtyBottomForm] = useState(false);
    const {userActivityLogId, encString} = useParams();
    const {errorMessage, bottomFormErrorMessage} = useSelector(state => state.register);
    const {
        errorMessage: registerInfoErrorMessage,
        bottomFormErrorMessage: registerBottomFormInfoErrorMessage
    } = useSelector(state => state.registerInfo);
    const [showPassword, setShowPassword] = useState(false);
    const [show, setShow] = useState(false);
    const [currentAccessToken, setCurrentAccessToken] = useState('');
    const [formOption, setFormOption] = useState('');
    const [registerFormRequired, setRegisterFormRequired] = useState(true);
    const [inviteFormRequired, setInviteFormRequired] = useState(true);
    const {setHideTemporaryOrbitAccessModal, hideTemporaryOrbitAccessModal} = React.useContext(AppContext);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [resetLogin, setResetLogin] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isTemporaryOrbitAccessUser, accessToken, counterToken, hideRenew} = useParams();
    const user = JSON.parse(getUser());

    useEffect(() => {
        if (errorMessage || registerBottomFormInfoErrorMessage) {
            toast.error(registerBottomFormInfoErrorMessage ? registerBottomFormInfoErrorMessage : bottomFormErrorMessage)
        }

    }, [registerInfoErrorMessage, errorMessage])

    useEffect(async () => {
        /*
        if (!resetLogin) {
            setResetLogin(true);
            dispatch({
                type: 'RESET'
            });
            logout();
            setLoginScreenStatus(true);
        }
         */

        setCurrentAccessToken(accessToken);

        if (encString === undefined) {
            if (userActivityLogId) {
                const ca = await registerInfo({id: userActivityLogId}, (data) => {
                    setFirstName(data.firstName);
                    setLastName(data.lastName);
                    setEmailAddress(data.emailAddress);
                    setPhoneNumber(data.phoneNumber);
                    setActivationHeadingText(data.activationHeadingText);

                    if (data && data.firstName && data.lastName && data.emailAddress && data.phoneNumber) {
                        setIsDirty(true);
                    }
                });
                await dispatch(ca);
            }
        }

        //get default orbit for logged in user
    }, []);

    const renewLogin = async (e) => {
        setHideTemporaryOrbitAccessModal(false);
        e.preventDefault();
        logout();

        navigate('/logintempuser' + (accessToken ? '/' + accessToken : '') + (counterToken ? '/' + counterToken : ''));
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if (formOption === REGISTER) {
            if (!await validateRegisterAllFields())
                return;

            const registerUserFromQrCodeTempUser = await registerFromQrCodeTempUser({
                userName,
                firstName,
                lastName,
                emailAddress,
                phoneNumber,
                password,
                accessToken
            }, (data) => {
                logout();
                dispatch({
                    type: 'RESET'
                });
                navigate('/');
            })
            await dispatch(registerUserFromQrCodeTempUser);
        } else if (formOption === SEND_INVITE) {
            if (!await validateRegisterBottomFormAllFields())
                return;

            const sendUserInviteFromTempUser = await inviteTempUser({
                firstName: bottomFormFirstName,
                emailAddress: bottomFormEmailAddress,
                accessToken
            }, (data) => {
                logout();
                dispatch({
                    type: 'RESET'
                });
                navigate('/');
            })
            await dispatch(sendUserInviteFromTempUser);
        }
    }

    const changeUserName = (e) => setUserName(e.target.value);
    const changeFirstName = (e) => setFirstName(e.target.value);

    const changeBottomFormFirstName = (e) => setBottomFormFirstName(e.target.value);
    const changeLastName = (e) => setLastName(e.target.value);
    const changeEmailAddress = (e) => setEmailAddress(e.target.value);

    const changeBottomFormEmailAddress = (e) => setBottomFormEmailAddress(e.target.value);
    const changePhoneNumber = (e) => setPhoneNumber(e.target.value);
    const changePassword = (e) => setPassword(e.target.value);
    const changeConfirmPassword = (e) => setConfirmPassword(e.target.value);

    const validateUserNameField = async (e) => {
        await setIsDirty(true);
        await setUserNameError(validateUserName(userName));
    }

    const validateFirstNameField = async (e) => {
        await setIsDirty(true);
        await setFirstNameError(validateFirstName(firstName));
    }

    const validateBottomFormFirstNameField = async (e) => {
        await setIsDirtyBottomForm(true);
        await setBottomFormFirstNameError(validateFirstName(bottomFormFirstName));
    }

    const validateLastNameField = async (e) => {
        await setIsDirty(true);
        await setLastNameError(validateLastName(lastName));
    }

    const validateEmailAddressField = async (e) => {
        await setIsDirty(true);
        await setEmailAddressError(validateEmailAddress(emailAddress));
    }

    const validateBottomFormEmailAddressField = async (e) => {
        await setIsDirtyBottomForm(true);
        await setBottomFormEmailAddressError(validateEmailAddress(bottomFormEmailAddress));
    }

    const validatePhoneNumberField = async (e) => {
        await setIsDirty(true);
        await setPhoneNumberError(validatePhoneNumber(phoneNumber));
    }

    const validatePasswordField = async (e) => {
        await setIsDirty(true);
        setConfirmPasswordError(validateConfirmPassword(password, confirmPassword));
        setPasswordError(validatePassword(password, confirmPassword));
    }

    const validateConfirmPasswordField = async (e) => {
        setConfirmPasswordError(validateConfirmPassword(password, confirmPassword));
        setPasswordError(validatePassword(password, confirmPassword));
    }

    const validateRegisterBottomFormAllFields = async () => {
        await setIsDirtyBottomForm(true);
        const invalidFirstName = validateFirstName(bottomFormFirstName);
        const invalidEmailAddress = validateEmailAddress(bottomFormEmailAddress);

        setBottomFormFirstNameError(invalidFirstName);
        setBottomFormEmailAddressError(invalidEmailAddress);

        if (invalidFirstName.length > 0 ||
            invalidEmailAddress.length > 0)
            return false;
        else
            return true;
    }

    const validateRegisterAllFields = async () => {
        await setIsDirty(true);
        const invalidUserName = validateUserName(userName);
        const invalidFirstName = validateFirstName(firstName);
        const invalidLastName = validateLastName(lastName);
        const invalidEmailAddress = validateEmailAddress(emailAddress);
        const invalidPhoneNumber = validatePhoneNumber(phoneNumber);
        const invalidPassword = validatePassword(password, confirmPassword);
        const invalidConfirmPassword = validateConfirmPassword(password, confirmPassword);

        setUserNameError(invalidUserName);
        setFirstNameError(invalidFirstName);
        setLastNameError(invalidLastName);
        setEmailAddressError(invalidEmailAddress);
        setPhoneNumberError(invalidPhoneNumber);
        setPasswordError(invalidPassword);
        setConfirmPasswordError(invalidConfirmPassword);

        if (invalidUserName.length > 0 ||
            invalidFirstName.length > 0 ||
            invalidLastName.length > 0 ||
            invalidEmailAddress.length > 0 ||
            invalidPhoneNumber.length > 0 ||
            invalidPassword.length > 0 ||
            invalidConfirmPassword.length > 0)
            return false;
        else
            return true;
    }

    return (
        <div className="w-100 gradient">
            <div className="w-100">
                <ToastContainer />
                <Container className="registration">
                    <div className="pageContainer">
                        <h1 className="text-center title headlineText">Activate my guide now.</h1>
                        <Box className="material-box" component="form" onSubmit={submitForm} autoComplete="off">
                            <div className="card-body">
                                <div className="formFlexContainer">
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={userNameError}>
                                            <TextField className="w-100" name="userName" id="userName" label="User Name"
                                                       variant="outlined" value={userName} onChange={changeUserName}
                                                       onBlur={validateUserNameField}
                                                       error={!!userNameError} required={registerFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={firstNameError}>
                                            <TextField className="w-100" name="firstName" id="firstName"
                                                       label="First Name"
                                                       variant="outlined" value={firstName} onChange={changeFirstName}
                                                       onBlur={validateFirstNameField}
                                                       error={!!firstNameError} required={registerFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={lastNameError}>
                                            <TextField className="w-100" name="lastName" id="lastName" label="Last Name"
                                                       variant="outlined" value={lastName} onChange={changeLastName}
                                                       onBlur={validateLastNameField}
                                                       error={!!lastNameError} required={registerFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={emailAddressError}>
                                            <TextField className="w-100" name="email" id="email" type="email"
                                                       label="Email (Required)"
                                                       variant="outlined" value={emailAddress}
                                                       onChange={changeEmailAddress}
                                                       onBlur={validateEmailAddressField}
                                                       error={!!emailAddressError} required={registerFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={phoneNumberError}>
                                            <InputMask
                                                className="w-100" name="phone" id="phone"
                                                variant="outlined"
                                                mask="(999) 999-9999"
                                                value={phoneNumber} onChange={changePhoneNumber}
                                                onBlur={validatePhoneNumberField}
                                                maskChar=" "
                                            >
                                                {() => <TextField className="w-100" type="phone" variant="outlined"
                                                                  label="Phone"
                                                                  />}
                                            </InputMask>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={passwordError}>
                                            <TextField type={showPassword ? "text" : "password"} className="w-100"
                                                       name="password" id="password" label="Password"
                                                       variant="outlined" onChange={changePassword}
                                                       autoComplete="new-password"
                                                       onBlur={validatePasswordField}
                                                       error={!!passwordError} required={registerFormRequired}
                                                       InputProps={{
                                                           endAdornment: (
                                                               <InputAdornment position="end">
                                                                   <a
                                                                       onClick={handleClickShowPassword}
                                                                       onMouseDown={handleMouseDownPassword}
                                                                       style={{color: '#3399cc'}}

                                                                   >
                                                                       {showPassword ? 'hide' : 'show'}
                                                                   </a>
                                                               </InputAdornment>
                                                           )
                                                       }}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={confirmPasswordError}>
                                            <TextField type={showPassword ? "text" : "password"} className="w-100"
                                                       name="confirmPassword" id="confirmPassword"
                                                       label="Re-Enter Password"
                                                       variant="outlined" onChange={changeConfirmPassword}
                                                       onBlur={validateConfirmPasswordField}
                                                       error={!!confirmPasswordError}
                                                       required={registerFormRequired} InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <a
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            style={{color: '#3399cc'}}

                                                        >
                                                            {showPassword ? 'hide' : 'show'}
                                                        </a>
                                                    </InputAdornment>
                                                )
                                            }}/>
                                            <span
                                                className="validationError">{registerInfoErrorMessage ? registerInfoErrorMessage : errorMessage}</span>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="buttonContainer loginButtonContainer">
                                        <Button className="loginUIComp center" type="submit" variant="contained"
                                                color="primary" value="Activate" onClick={() => {
                                            setInviteFormRequired(false);
                                            setRegisterFormRequired(true);
                                            setFormOption(REGISTER);
                                        }}
                                                disabled={userNameError?.length > 0 || firstNameError?.length > 0 || lastNameError?.length > 0 || phoneNumberError?.length > 0 || emailAddressError?.length > 0 || passwordError?.length > 0 || confirmPasswordError?.length > 0}>
                                            Activate
                                        </Button>
                                    </div>
                                    <br/><br/>
                                    <h1 className="text-center title headlineText">Or, activate guide later.</h1>

                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={bottomFormFirstNameError}>
                                            <TextField className="w-100" name="bottomFormFirstName"
                                                       id="bottomFormFirstName"
                                                       label="First Name"
                                                       variant="outlined" value={bottomFormFirstName}
                                                       onChange={changeBottomFormFirstName}
                                                       onBlur={validateBottomFormFirstNameField}
                                                       error={!!bottomFormFirstNameError}
                                                       required={inviteFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <div className="formInput">
                                        <InputWithErrorMessage errorMessage={bottomFormEmailAddressError}>
                                            <TextField className="w-100" name="bottomFormEmail" id="bottomFormEmail"
                                                       type="email" label="Email"
                                                       variant="outlined" value={bottomFormEmailAddress}
                                                       onChange={changeBottomFormEmailAddress}
                                                       onBlur={validateBottomFormEmailAddressField}
                                                       error={!!bottomFormEmailAddressError}
                                                       required={inviteFormRequired}/>
                                        </InputWithErrorMessage>
                                    </div>
                                    <span
                                        className="validationError">{registerBottomFormInfoErrorMessage ? registerBottomFormInfoErrorMessage : bottomFormErrorMessage}</span>
                                    <div className="buttonContainer loginButtonContainer">
                                        <Button className="loginUIComp center" type="submit" variant="contained"
                                                color="primary" value="SEND INVITE"
                                                onClick={() => {
                                                    setRegisterFormRequired(false);
                                                    setInviteFormRequired(true);
                                                    setFormOption(SEND_INVITE);
                                                }}
                                                disabled={bottomFormFirstNameError?.length > 0 || bottomFormEmailAddressError?.length > 0}>
                                            SEND INVITE
                                        </Button>
                                        {
                                            !(hideRenew === "true") ?
                                                <div className="w-100 text-center">
                                                    <a href="javascript:;" className="btn-link text-center"  onClick={renewLogin}>Continue
                                                        without providing email address</a>
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </div>
                </Container>
            </div>
        </div>

    )
};
export default RegisterForTempUser;
