import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/51bdd658df7cdfe452eafa7098542a93.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#8DCA64",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">
                Patient Success Stories
              </h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                        <YouTube videoId="umWIvIe9144" opts={opts} />
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          A life-changing experience for many.
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L14 Paragraph */}

                  <div className="lego l00paragraph">
                    <p>
                      Hear the story of one patient and his journey with the
                      Living Well Center to be able to qualify for badly needed,
                      life-changing hip surgery.
                    </p>
                  </div>

                  {/* End L14 Paragraph */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>

                  {/* End L66 Divider Rule */}

                  {/* L11A Image Max Width 400px */}
                  <div className="lego l011 legoMargin3">
                    <img
                      alt=""
                      className="imageW100 maxWidth600 noBorderRadius"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/5044da583dcf347c03b9ed0d015df153.png"
                    />
                  </div>
                  {/* End L11A Image Max Width 400px */}

                  {/* L14B Paragraph With Bold Italic Spans */}
                  <div className="lego l00paragraph legoMargin1">
                    <p className="legoMargin1">
                      <span className="bold italic ">David’s story. </span>
                    </p>
                    <p>
                       When David first came to the Living Well Center, he was
                      experiencing significant pain. Because of the pain, David
                      was limited in what he could do physically. His goal was
                      to travel and continue his photography. And you could tell
                      he was ready to commit to an overhaul of his health. We
                      talked about how his back was driving the problem, but
                      the success.
                    </p>
                  </div>
                  {/* End L14B Paragraph With Bold Italic Spans */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      <a
                        className="blueLinkfont18"
                        href="https://www.ortho.wustl.edu/content/Whats-New/2428/showcasing-success-living-well-center-patient-overhauls-health.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Read David's Story
                      </a>
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}
                  {/* L11A Image Max Width 400px */}
                  <div className="lego l011 legoMargin3">
                    <img
                      alt=""
                      className="imageW100 maxWidth600 noBorderRadius"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/729aa453323f51a08b439967e41e6046.png"
                    />
                  </div>
                  {/* End L11A Image Max Width 400px */}

                  {/* L14B Paragraph With Bold Italic Spans */}
                  <div className="lego l00paragraph legoMargin1">
                    <p className="legoMargin1">
                      <span className="bold italic ">Virginia’s story. </span>
                    </p>
                    <p>
                       Virginia’s story.   Virginia’s journey at the Living Well
                      Center is a testament to the power of investing in your
                      own health. She encourages others not to be afraid to seek
                      help and emphasizes it's not just for those who are
                      already sick, but for anyone wanting to improve their
                      quality of life, "This is for healthy people, too!"
                    </p>
                  </div>
                  {/* End L14B Paragraph With Bold Italic Spans */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      <a
                        className="blueLinkfont18"
                        href="https://www.ortho.wustl.edu/content/Whats-New/2646/thriving-with-the-living-well-center.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Read about Virginia’s journey
                      </a>
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  {/* L11A Image Max Width 400px */}
                  <div className="lego l011 legoMargin3">
                    <img
                      alt=""
                      className="imageW100 maxWidth600 noBorderRadius"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/820ed694a1f00ee5b753f65a8ae6cb8e.png"
                    />
                  </div>
                  {/* End L11A Image Max Width 400px */}

                  {/* L14B Paragraph With Bold Italic Spans */}
                  <div className="lego l00paragraph legoMargin1">
                    <p className="legoMargin1">
                      <span className="bold italic ">Judy’s story. </span>
                    </p>
                    <p>
                      Judy had been managed (through her health issues) by
                      narcotics for over a decade and within three months of
                      starting this new lifestyle, she was off of her
                      narcotics.  People would say to her that now her faced
                      glowed, her eyes sparkled; it was just a major change in
                      you
                    </p>
                  </div>
                  {/* End L14B Paragraph With Bold Italic Spans */}

                  {/* L14D Paragraph External Link */}
                  <div className="lego l00paragraph legoMargin5">
                    <p>
                      <a
                        className="blueLinkfont18"
                        href="https://www.ortho.wustl.edu/content/Patient-Care/7140/Services/Living-Well-Center.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Watch Judy’s story
                      </a>
                    </p>
                  </div>
                  {/* End L14D Paragraph External Link */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_3;
