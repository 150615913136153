import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_1_3 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/2445d49ce59d1ea4f009a390ca216e11.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#8DCA64",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Meet Our Team</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                          <YouTube videoId="umWIvIe9144" opts={opts} />
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          A TEAM APPROACH AT THE LIVING WELL CENTER
                        </span>
                        <br />
                        <span className="videoSubTitleTreatment">
                          Dr. Devyani Hunt, Director of the Washington
                          University Living Well Center
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin3">
                    <p>
                      The Living Well Center is a special place because it takes
                      into account all the things that make a person whole. To
                      make that possible, we have an entire team of providers
                      that are dedicated to the success of each of their
                      patients. Each team member has a different background
                      which brings a unique perspective to treating the whole
                      patient.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L11A Image Max Width 400px */}
                  <div className="lego l011 legoMargin4">
                    <img
                      alt=""
                      className="imageW100 noBorderRadius maxWidth700"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/a6f33aa23e47c024330ad5d6ce4b8c8a.png"
                    />
                  </div>
                  {/* End L11A Image Max Width 400px */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps2">
                      WAYS THE LIVING WELL CENTER CAN HELP
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* Lego L098 Bold Italic text & content with Link */}
                  <div className="lego l098 legoMargin4">
                    <p>
                      Center Director:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/2695/Find-a-Physician/Physician-Directory/Devyani-Hunt-MD/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Devyani Hunt, MD
                      </a>
                      <br />
                      Acupuncture:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/2717/Find-a-Physician/Physician-Directory/Chi-Tsai-Tang-MD/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Chi Tang,MD
                      </a>
                      <br />
                      Manual Medicine:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/2705/Find-a-Physician/Physician-Directory/John-Metzler-MD/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                         John Metzler,MD
                      </a>
                      <br />
                      Massage Therapy:{" "}
                      <a href="" rel="noreferrer noopener" target="_blank">
                        Amy Whitelaw, MT
                      </a>
                      <br />
                      Massage Therapy: {" "}
                      <a href="" rel="noreferrer noopener" target="_blank">
                        Mary Bouse, MT
                      </a>
                      <br />
                      Nutrition Counseling:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/8321/Find-a-Physician/Physician-Directory/Nartana-Mehta-RD-LD/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                         Nartana Mehta, RD, LD
                      </a>
                      <br />
                      Physical Therapy:{" "}
                      <a href="" rel="noreferrer noopener" target="_blank">
                         Susan Peck, PT
                      </a>
                      <br />
                      Physical Therapy:{" "}
                      <a href="" rel="noreferrer noopener" target="_blank">
                         Matthew Foster, PT, DPT
                      </a>
                      <br />
                      Licensed Clinical Social Worker:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/8497/Find-a-Physician/Physician-Directory/Kayla-Hambleton-MSW-LCSW/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                         Kayla Hambleton, MSW, LCSW
                      </a>
                      <br />
                      Licensed Clinical Social Worker: {" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/8453/Find-a-Physician/Physician-Directory/Juliana-Varela-LCSW/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Juliana Varela, LCSW
                      </a>
                      <br />
                      Smoking Cessation:{" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/3113/Find-a-Physician/Physician-Directory/Corri-Payton-BSN-MSN-ANP/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Corri Payton, NP
                      </a>
                      <br />
                      Nurse Practitioner:  {" "}
                      <a
                        href="https://www.ortho.wustl.edu/content/Patient-Care/8462/Find-a-Physician/Physician-Directory/Sara-Kellahan-MSN-APRN-AGPCNP-C/Bio.aspx"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Sara Kellahan, MSN, APRN, AGPCNP
                      </a>
                      <br />
                      Head Athletic Trainer:{" "}
                      <a href="" rel="noreferrer noopener" target="_blank">
                        Lauren Baumer, MSEd, ATC
                      </a>
                      <br />
                    </p>
                  </div>
                  {/* End Lego L098 Bold Italic text & content with Link */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_1_3;
