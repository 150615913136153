import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from "../../../components/auth/login/index.js"
import LoginHersUser from "../../../components/auth/loginhersuser/index.js"
import ForgotPassword from "../../../components/auth/forgotpassword/index.js"
import OneTimePassword from "../../../components/auth/onetimepassword/index.js"
import Register from "../../../components/auth/register/index.js"
import Home from "../../../components/home/index.js";
import ResetPassword from "../../../components/auth/resetpassword/index.js"
import TopNavLoggedOut from "../topNavLoggedOut";
import Pritikin_video_sample from "../../../components/pritikin/pritikin-video-sample";
import LandingPage from '../../landing';
import UnityPointLandingPage from '../../landing/unitypoint';
import CHIHealthLandingPage from '../../landing/chi-health';
import BooneHealthLandingPage from "../../landing/boone-health";
import {Container, Button , Alert} from 'react-bootstrap';
import { useState } from 'react';
import TempLogin from "../../auth/templogin/index.js";
import RegisterForTempUser from "../../auth/registerForTempUser/index.js";
import {pathToHide} from "../../landing/hideNavBarForPath.js";


const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const [show, setShow] = useState(true);
    const location = useLocation();
    const nameWoParameters = location.pathname.split('/');

    return (
        <div>
            {pathToHide.indexOf(nameWoParameters[1]) === -1 ? <TopNavLoggedOut/> : ''}
            {/*<Alert className="alertLoginMaintenance" style={{margin: 'auto', width: '98%'}} variant="dark"  onClose={() => setShow(false)} closeLabel="Close Alert" >*/}
            {/*    <Alert.Heading>*/}
            {/*        The CareOrbit Application will be undergoing maintenance on Thursday, July 13th from 10pm to 2am. We apologize for any inconvenience.*/}
            {/*    </Alert.Heading>*/}
            {/*</Alert>*/}
        <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/loginhersuser/:orbitIntegrationCode' element={<LoginHersUser/>}/>
            <Route path='/logintempuser/:accessToken' element={<TempLogin/>} />
            <Route path='/logintempuser/:accessToken/:counterToken' element={<TempLogin/>} />
            <Route path='/forgotPassword' element={<ForgotPassword/>}/>
            <Route path='/oneTimePassword' element={<OneTimePassword/>}/>
            <Route path='/register/:userActivityLogId' element={<Register/>}/>
			<Route path='/register/qr/:encString' element={<Register/>}/>
            <Route path='/register/tempuser/:isTemporaryOrbitAccessUser/:accessToken/:userActivityLogId' element={<Register/>}/>
            <Route path='/register/tempuser/posttrial/:isTemporaryOrbitAccessUser/:accessToken/:counterToken/:hideRenew' element={<RegisterForTempUser/>}/>
            {/*
			<Route path='/register/tempuser/:isTemporaryOrbitAccessUser/:accessToken' element={<Register/>}/>
            <Route path='/register/tempuser/:isTemporaryOrbitAccessUser/:accessToken/:userActivityLogId' element={<Register/>}/>
            <Route path='/register/tempuser/posttrial/:isTemporaryOrbitAccessUser/:accessToken/:counterToken' element={<RegisterForTempUser/>}/>
            */}
            <Route path='/resetPassword' element={<ResetPassword/>}/>
			<Route path="/landing/:encString" element={<LandingPage/>}/>
			<Route path="/unitypoint" element={<UnityPointLandingPage/>}/>
            <Route path="/CHIhealth" element={<CHIHealthLandingPage/>}/>
            <Route path="/BooneHealth" element={<BooneHealthLandingPage/>}/>
            <Route path="/pritikin-video-sample" element={<Pritikin_video_sample/>}/>
            <Route path='/' element={<Home/>}/>
        </Routes>
        </div>

    )
}

export default PublicRoute;
