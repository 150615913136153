import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const InjectionSupport2_3_6 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100">
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L1 Tile Title Header */}
                  <div className="lego l001 greenColorAndBorder legoMargin5">
                    <h1 className="tileTitle">
                      Interlaminar Epidural Injection
                    </h1>
                    <hr className="w-100 l001Rule" />
                  </div>
                  {/* End Tile Title Header L1 */}

                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">About the Procedure.</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin1">
                    <p>
                      An interlaminar epidural injection is a procedure that
                      involves inserting a needle between the laminae of two
                      vertebrae to deliver medication into the epidural space.{" "}
                    </p>
                    <p>
                      <span className="boldItalic">
                        The injection includes:
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L53 Bullet List */}
                  <div className="lego l053 smallFont legoMargin3">
                    <ul className="w-100">
                      <li className="w-100 legoMargin2">
                        <span className="bold">Lidocaine</span> (numbing
                        medicine). The numbing medicine usually lasts for 30 -
                        45 minutes. During this time, your leg may feel weak and
                        numb due to the effects of the numbing medicine. If this
                        occurs, it is important to stay safe and do not stand or
                        walk without assistance until the numbing medicine wears
                        off. Do not drive for one hour after the injection.
                      </li>
                      <li className="w-100 legoMargin2">
                        <span className="bold">Triamcinolone</span> (steroid
                        medicine for inflammation and pain). The steroid will
                        start working within the next three to five days and can
                        take up to two weeks for the full effect.
                      </li>
                      <li className="w-100">
                        This procedure uses a contrast agent, which is a safe
                        substance that makes a part of your body show up more
                        clearly on an X-ray to ensure proper placement of the
                        medicine.
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}

                  {/* L11 Image */}
                  <div className="lego l011 legoMargin5">
                    <img
                      className="imageW100"
                      src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/1fd82ccc5989da15310cad328b01981e.png"
                    />
                  </div>
                  {/* End L11 Image */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">PRIOR TO THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin5">
                    <div className="iconContainer tallHeight p-0">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/doNotTakeMeds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      {/* L14 Paragraph */}
                      <div className="lego l00paragraph legoMargin3">
                        <p className="legoMargin1">
                          Certain medications* like antiplatelet and
                          anticoagulants{" "}
                          <span className="bold">must be stopped prior</span> to
                          receiving a spinal injection.{" "}
                          <span className="bold italic">
                            Please consult with the prescribing provider
                          </span>{" "}
                          for directions on stopping prior to your injection. In
                          the event your healthcare provider doesn't want
                          medications stopped, contact our office immediately.{" "}
                        </p>

                        <p>
                          <span className="note">
                            *Ibuprofen, Meloxicam, Diclofenac, etc., Plavix
                            (Clopidogrel), Pradaxa (Dabigatran), Xarelto
                            (Rivaroxaban), Acenocoumarol, Aggregnox, Persantine
                            (Dipyridamole), Arixtra (Fondaparinux), Brilinta
                            (Ticagrelor), Effient (Prasugrel), Fragmin
                            (Dalteparin), Heparin, Lovenox (Enoxaparin), Pletal
                            (Cilostazol), Savaysa (Edoxaban).
                          </span>
                        </p>
                      </div>
                      {/* End L14 Paragraph */}

                      {/* L53 Bullet List */}
                      <div className="lego l053 smallFont">
                        <ul className="w-100">
                          <li className="w-100 legoMargin3">
                            <span className="boldItalic">
                              Do NOT stop any other medication
                            </span>{" "}
                            (blood pressure meds, diabetes meds, etc.)
                          </li>
                          <li className="w-100 legoMargin3">
                            <span className="boldItalic">
                              Alert our office if you have any history of
                              allergies to local anesthetics
                            </span>{" "}
                            (such as Novocain or lidocaine).
                          </li>
                          <li className="w-100 legoMargin3">
                            If you have an{" "}
                            <span className="boldItalic">
                              allergy to X-ray contrast dye
                            </span>
                            , please notify your healthcare provider prior to
                            the procedure.
                          </li>
                        </ul>
                      </div>
                      {/* End L53 Bullet List */}
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  <div className="col-12 m-auto" style={{ width: "80%" }}>
                    {/* L3 Subhead Upper and Lower */}
                    <div className="lego l003B">
                      <h2 className="subHeadCaptalizedItalic">
                        <span className="playButton">&#9658;</span>{" "}
                        {/* Play button as an SVG */}
                        Important Notice:
                      </h2>
                    </div>
                    {/* End L3 Subhead Upper and Lower */}

                    {/* L14A Paragraph With Bold Spans */}
                    <div className="lego l00paragraph1 legoMargin1">
                      <p>
                        For your safety, patients with a{" "}
                        <span className="boldItalic">
                          BMI of 50 or above cannot be scheduled{" "}
                        </span>
                        for fluoroscopy (fluoro) procedures at our South County
                        CAM and Chesterfield OC locations. Please contact our
                        office if you need assistance with rescheduling or have
                        any questions.
                      </p>
                    </div>
                    {/* End L14A Paragraph With Bold Spans */}

                    {/* L66 Divider Rule */}
                    <div className="lego l066 legoMargin1">
                      <hr className="orangeHorizonalRule" />
                    </div>
                    {/* End L66 Divider Rule */}
                  </div>

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">DAY OF THE PROCEDURE:</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin5">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_clock.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold">
                          Please arrive 30 minutes prior to your injection time
                          for registration.
                        </span>
                        &nbsp;If you arrive more than 15 minutes past your
                        scheduled injection time, your procedure may need to be
                        rescheduled.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin3">
                    <h2 className="subHeadAllCaps">WHEN YOU GET HOME</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_driving.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="boldItalic">
                          Do not drive for one hour after the procedure
                        </span>
                        &nbsp;as your leg may feel weak or numb from the numbing
                        medicine.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_meds.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Resume your medicine
                        </span>
                        &nbsp;including any blood thinner you held prior to the
                        injection.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_fork_meat.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>Resume your normal diet.</p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_icepack.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        For soreness, you may place an ice pack once an hour at
                        the injection site for 15-20 minutes.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_bath.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        To prevent infection, do not take a{" "}
                        <span className="bold italic">bath, swim</span> or sit
                        in a <span className="bold italic">Jacuzzi</span> or{" "}
                        <span className="bold italic">hot tub</span> for the
                        next two days.{" "}
                        <span className="bold">You may shower</span>.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin2">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_glass_water.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        Drink plenty of fluids to decrease a chance of a
                        headache associated with steroids.
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L83 Icon Color Bar New */}
                  <div className="lego l083 legoMargin4">
                    <div className="iconContainer">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/fluo_icon_no_exer.svg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold italic">
                          Do not exercise for 24 hours
                        </span>
                        , regular day-to-day activities are OK to perform. You
                        may resume your physical therapy appointments in 24
                        hours.{" "}
                      </p>
                    </div>
                  </div>
                  {/* End L83 Icon Color Bar New */}

                  {/* L85 Color Bar Top Content Block */}
                  <div className="lego l085 legoMargin5">
                    <div className="headerColorBar">
                      <h3>For Diabetic patients:</h3>
                    </div>
                    <div className="contentContainer">
                      <ul>
                        <li className="legoMargin2">
                          Steroid injections may lead to higher blood glucose
                          (sugar) levels temporarily. Most commonly, the higher
                          levels will return to normal within 1-3 days, though
                          effects may last longer. Rises in blood glucose levels
                          may be more significant in patients with poorly
                          controlled type 2 diabetes (those with HbA1c levels
                          greater than 7) and those with type I diabetes.
                        </li>
                        <li className="legoMargin2">
                          Check fasting (early morning prior to first meal of
                          the day) and post-prandial (following meals) blood
                          glucose levels.
                        </li>
                        <li className="legoMargin2">
                          Contact the physician who manages your diabetes if
                          your blood sugar is significantly elevated (for
                          example, over 100mg/dL higher than your pre-injection
                          level) or if blood sugar levels remain significantly
                          elevated 2 days after receiving the injection, to
                          discuss whether a change in medication dosing is
                          needed.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End L85 Color Bar Top Content Block */}

                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps">WHEN YOU GET HOME</h2>
                  </div>
                  {/* End L4 Subhead Upper */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin5 w-80 m-auto">
                    <p>
                      <span className="bold">
                        For urgent concerns after hours
                      </span>
                      , call our exchange at{" "}
                      <span className="bold">
                        <a className="phoneLink" href="tel:314-388-5550">
                          314-388-5550
                        </a>
                        .
                      </span>
                      <br /> For all other questions regarding the procedure,
                      please call our office at &nbsp;
                      <span className="bold">
                        <a className="phoneLink" href="tel:3147472823">
                          314-747-2823
                        </a>
                      </span>
                      .
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L84 Block */}
                  <div className="lego l084 legoMargin5">
                    <h3 className="contentTitle">
                      Billing for Injection Procedures
                    </h3>
                    <p>
                      Our Washington University orthopedic specialists treat
                      patients at BJC HealthCare facilities, which means you may
                      receive two separate bills. One bill is for the physician
                      and the other is for the facility charges.{" "}
                    </p>

                    {/* L82? Content 2 sides 50% width */}
                    <div className="lego l082 p-0">
                      <div className="contentLeftSide">
                        {/* L11 Image */}
                        <div className="lego l011 legoMargin3">
                          <img
                            className="imageW100 noBorderRadius"
                            src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/cbc217ae4fc61ef763bbd58ff2d2040e.png"
                          />
                        </div>
                        {/* End L11 Image */}
                      </div>
                      <div className="contentRightSide">
                        <div className="lego l00paragraph legoMargin1">
                          <p>
                            If you have questions regarding a price estimation
                            for the services or a recently received bill, please
                            contact:
                          </p>
                          <p>
                            BJC HealthCare Price Estimation:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3147478845">
                                314-747-8845
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18447478845">
                                844-747-8845
                              </a>
                            </span>
                          </p>
                          <p>
                            Washington University Patient Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3142730500">
                                314-273-0500
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18008629980">
                                800-862-9980
                              </a>
                            </span>
                          </p>
                          <p>
                            BJC HealthCare Patient Billing Services:
                            <br />
                            <span className="bold">
                              <a className="phoneLink" href="tel:3143628400">
                                314-362-8400
                              </a>{" "}
                              or toll free{" "}
                              <a className="phoneLink" href="tel:18553628400">
                                855-362-8400
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End L82? Content 2 sides 50% width */}
                  </div>
                  {/* End L84 Block */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InjectionSupport2_3_6;
