import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_1_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/6ec0c408faa1da4b314fb040ba5e4ac5.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#8DCA64",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">About Us</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild">
                <section>
                  {/* L2 */}
                  <div className="lego l002 legoMargin3">
                    <h2 className="l2Headline">
                      Welcome to the Living Well Center.
                    </h2>
                  </div>
                  {/* End L2 */}

                  {/* L31 Video Box Large */}
                  <div className="lego l031 legoMargin2">
                    <div className="videoContainer">
                      <div className="embedContainer originalPadding">
                        <YouTube videoId="umWIvIe9144" opts={opts} />
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          A SPECIAL PLACE FOR CARE
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      At the Washington University Living Well Center®, we’re
                      changing the way healthcare is delivered. With a
                      multidisciplinary team of experts, we provide
                      comprehensive care and treat the root cause of a patient’s
                      problem — not just the symptoms — with the principles of
                      lifestyle medicine.
                    </p>
                  </div>
                  {/* End L14 Paragraph */}
                  {/* L3 Subhead Upper and Lower */}
                  <div className="lego l003">
                    <h2 className="subHeadCaptalizedItalic legoMargin4">
                      We offer a variety of services to treat the whole patient,
                      including
                    </h2>
                  </div>
                  {/* End L3 Subhead Upper and Lower */}

                  {/* L95A 4 horizontal color boxes with titles */}
                  <div className="lego l095A ">
                    <div className="contentBox box1">
                      <p>
                        NUTRITIONAL <br />
                        COUNSELING
                      </p>
                    </div>
                    <div className="contentBox box1">
                      <p>WEIGHT LOSS</p>
                    </div>
                    <div className="contentBox box1">
                      <p>
                        MOVEMENT AND <br />
                        EXCERCISE
                      </p>
                    </div>

                    <div className="contentBox box1">
                      <p>
                        PHYSICAL
                        <br /> THERAPY
                      </p>
                    </div>
                  </div>
                  {/* End L95A 4 horizontal color boxes with titles */}
                  {/* L95A 4 horizontal color boxes with titles */}
                  <div className="lego l095A ">
                    <div className="contentBox box1">
                      <p>
                        SHARED MEDICAL
                        <br /> APPOINTMENTS
                      </p>
                    </div>
                    <div className="contentBox box1">
                      <p>
                        HEALTH <br />
                        OPTIMIZATION
                      </p>
                    </div>
                    <div className="contentBox box1">
                      <p>
                        BEHAVIORAL
                        <br />
                        HEALTH COUNSELING{" "}
                      </p>
                    </div>
                    <div className="contentBox box1">
                      <p>
                        SMOKING
                        <br />
                        CESSATION
                      </p>
                    </div>
                  </div>
                  {/* End L95A 4 horizontal color boxes with titles */}
                  {/* L95A 4 horizontal color boxes with titles */}
                  <div className="lego l095A ">
                    <div className="contentBox box1">
                      <p>TAI CHI & YOGA</p>
                    </div>
                    <div className="contentBox box1">
                      <p>MEDICAL MASSAGE</p>
                    </div>
                    <div className="contentBox box1">
                      <p>ACUPUNCTURE</p>
                    </div>
                    <div className="contentBox box1">
                      <p>
                        LONG COVID
                        <br />
                        PROGRAM{" "}
                      </p>
                    </div>
                  </div>
                  {/* End L95A 4 horizontal color boxes with titles */}

                  {/* L66 Divider Rule */}
                  <div className="lego l066">
                    <hr className="horizonalRule" />
                  </div>
                  {/* End L66 Divider Rule */}
                  {/* L4 Subhead Upper */}
                  <div className="lego l004 legoMargin2">
                    <h2 className="subHeadAllCaps2">
                      WAYS THE LIVING WELL CENTER CAN HELP
                    </h2>
                  </div>
                  {/* End L4 Subhead Upper */}
                  {/* L14 Paragraph */}
                  <div className="lego l00paragraph legoMargin2">
                    <p>
                      Your health is important. From sleep and nutrition to
                      exercise and mental health, many factors can impact your
                      overall well-being. At the Washington University Living
                      Well Center®, we bring together healthcare experts to help
                      you meet your personal health and wellness goals.
                    </p>
                    <p>
                      <span className="boldItalic">
                        The Center programs can help you:
                      </span>
                    </p>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L53 Bullet List */}
                  <div className="lego l053 legoMargin3 smallFont">
                    <ul className="w-100">
                      <li className="w-100 legoMargin1">
                        Improve your health for a successful surgery.
                      </li>
                      <li className="w-100 legoMargin1">
                        Target and a treat specific condition if you are not a
                        candidate for surgery.
                      </li>
                      <li className="w-100 legoMargin1">
                        To address a health issue to avoid the need for surgery.
                      </li>
                      <li className="w-100 legoMargin1">
                        To enhance quality of life for those facing Long
                        COVID-19.
                      </li>
                      <li className="w-100 legoMargin1">
                        Health optimization your to meet a specific goal, such
                        as healthy living after having a baby or training for an
                        athletic event....
                      </li>
                    </ul>
                  </div>
                  {/* End L53 Bullet List */}
                  {/* L31 Video Box Large */}
                  <div className="col-12 video">
                    <div className="w-100 legoMargin2">
                      <div className="floatLeft w-100">
                        <div className="embedContainer originalPadding">
                          <YouTube videoId="umWIvIe9144" opts={opts} />
                        </div>
                      </div>
                      <h3 className="videoTitle w-100 text-center text-dark">
                        Watch:{" "}
                        <span className="videoTitleTreatment">
                          ABOUT THE LIVING WELL CENTER
                        </span>
                      </h3>
                    </div>
                  </div>
                  {/* End L31 Video Box Large */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_1_1;
