import React from "react";
import Container from "react-bootstrap/Container";
import { Nav } from "react-bootstrap";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { HashLink as Link } from "react-router-hash-link";
import YouTube from "react-youtube";

const LivingWellCenter_6_1 = () => {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div>
      <div className="w-100 legosNewBuild livingWellCenterHeader">
        <section className="mb-0">
          {/* L6 Background image header bar */}
          <div className="lego l006 legoMargin5">
            <div
              className="backgroundImage"
              style={{
                backgroundImage:
                  "url(" +
                  "https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/ea043d0d2f2dadd759a04e88209b8679.jpeg" +
                  ")",
              }}
            >
              <div
                className="transparentBarTypeContainer"
                style={{
                  backgroundColor: "#FA4E36",
                }}
              >
                &nbsp;&nbsp;
              </div>
              <h2 className="l6SubheadWithBackground">Lifestyle as Medicine</h2>
            </div>
          </div>
          {/* End L6 Background image header bar */}
        </section>
        <Container>
          <div className="w-100">
            <div className="col-12 m-auto">
              <div className="whiteBackground floatLeft clearLeft legosNewBuild livingWellCenterHeader">
                <section>
                  {/* L2 */}
                  <div className="lego l002 legoMargin2">
                    <h2 className="l2Headline">What is Lifestyle Medicine?</h2>
                  </div>
                  {/* End L2 */}

                  {/* L14 Paragraph */}
                  <div className="col-10 m-auto">
                    <div className="lego l00paragraph ">
                      <p className="legoMargin2">
                        Lifestyle medicine is a medical specialty that uses
                        therapeutic lifestyle interventions as a primary
                        modality to treat chronic conditions including but not
                        limited to cardiovascular diseases, type 2 diabetes, and
                        obesity.
                      </p>
                      <p className="legoMargin2">
                        Lifestyle medicine certified clinicians are trained to
                        apply evidence-based, whole-person prescriptive
                        lifestyle change to treat and, when used intensively,
                        often reverse such conditions.
                      </p>
                      <p className="legoMargin3">
                        Applying the six pillars of lifestyle medicine — a whole
                        food plant- predominant eating pattern, physical
                        activity, restorative sleep, stress management,
                        avoidance of risky substances, and positive social
                        connections — also provides effective prevention for
                        these conditions.
                      </p>
                    </div>
                  </div>
                  {/* End L14 Paragraph */}

                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/c4e728b37d684d8279bcd536dcb5222d.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">
                          1. Whole food, plant-predominant nutrition
                        </span>
                      </p>
                      <p>
                        Extensive scientific evidence supports the use of a
                        whole- food, predominantly plant-based diet is an
                        important strategy in prevention of chronic disease,
                        treatment of chronic conditions and, in intensive
                        therapeutic doses, reversal of chronic illness.
                      </p>
                      <p>
                        Such a diet is rich in fiber, ioxidants, and nutrient
                        dense foods. Choose a variety of minimally processed
                        vegetables, fruits, whole grains, legumes, nuts and
                        seeds.
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}

                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/b6ac0ce7cf19be01d6341f0581155386.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">2. Physical activity</span>
                      </p>
                      <p>
                        Regular and consistent physical activity combats the
                        negative effects of sedentary behavior period it is
                        important that adults engage in both general physical
                        activity as well as purposeful exercise weekly as part
                        of overall health and resiliency.
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}
                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7a3f944e2d4119969d65663c9d9a5ecb.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">3. Stress management</span>
                      </p>
                      <p>
                        Stress can lead to improved health and productivity
                        comma or it can lead to anxiety comma depression comma
                        obesity comma immune dysfunction and more period helping
                        patients recognize negative stress responses comma
                        identify coping mechanisms and reduction techniques
                        leads to improved well-being period
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}
                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/956bba33a4df56079e46c707e4078980.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">
                          4. Avoidance of risky substances
                        </span>
                      </p>
                      <p>
                        Use of tobacco and excessive alcohol consumption have
                        been shown to increase the risk of chronic diseases and
                        death period treatments often take time comma different
                        approaches and many attempts period patients and support
                        are an important part of breaking risky substance
                        habits.
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}
                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/7151f0b68c69418af31568c49638e2ee.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">5. Restorative Sleep</span>
                      </p>
                      <p>
                        Sleep delays/interruptions have been shown to cause
                        sluggishness, low attention span, decreased sociability,
                        depressed mood, decreased deep sleep, decreased caloric
                        burn during the day, increased hunger and decreased
                        feeling of fullness, insulin resistance and decreased
                        performance. Strive for 7 or more hours per night for
                        optimal health.
                      </p>
                      <p>
                        Such a diet is rich in fiber, ioxidants, and nutrient
                        dense foods. Choose a variety of minimally processed
                        vegetables, fruits, whole grains, legumes, nuts and
                        seeds.
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}
                  {/* L83F Icon Color Bar New */}
                  <div className="lego l097 legoMargin3">
                    <div className="imgContainer legoMargin1">
                      <img
                        className="icon"
                        src="https://orbit-image-video-bucket.s3.us-east-2.amazonaws.com/feec0b037f84204dd10bdee0916c8336.jpeg"
                      />
                    </div>
                    <div className="contentContainer">
                      <p>
                        <span className="bold ">6. Social Connection</span>
                      </p>
                      <p>
                        Positive social connections and relationships affect our
                        physical, mental and emotional health. Leveraging the
                        power of relationships and social networks can help
                        reinforce healthy behaviors.
                      </p>
                    </div>
                  </div>
                  {/* End L83F Icon Color Bar New */}

                  <div className="col-12 backToTop">
                    <div className="w-100">
                      <p>
                        <a
                          className="specialUseBackToTop"
                          onClick={() =>
                            window.scrollTo({ top: 0, behavior: "smooth" })
                          }
                        >
                          BACK TO TOP{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default LivingWellCenter_6_1;
